import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../config/constants.js';
import { Redirect } from 'react-router-dom';
import ls from 'local-storage';
import Axios from 'axios';
import moment from 'moment';

const errors = {};
export default class AdminLogin extends Component {

	constructor(props) {
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			email: '',
			password: '',
			msg: '',
			fields: {},
            errors: [],
            btnStaus: true,	
			btnText: 'Login'
		}
	}
	onChangeEmail(e) {
		if(this.state.errors['email_error']){delete this.state.errors['email_error'];}
		this.setState({
			email: e.target.value,
			msg:''
		});
	}
	onChangePassword(e) {
		if(this.state.errors['password_error']){delete this.state.errors['password_error'];}
		this.setState({
			password: e.target.value,
			msg:''
		});
	}
	handleValidation(){
		let formIsValid = true;
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	
		if(!this.state.email){
			formIsValid = false;
			errors["email_error"] = "Please enter email id.";
		}
		if(this.state.email && !re.test(this.state.email)){
			formIsValid = false;
			errors["email_error"] = "Please enter valid email id.";
		}

		if(!this.state.password){
			formIsValid = false;
			errors["password_error"] = "Please enter password.";
		}

		this.setState({errors: errors});
		return formIsValid;
	}
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({					
				btnStaus:false,
				btnText: "Please Wait..."
			});
			const today = moment();
			const obj = {
				email: this.state.email,
				password: this.state.password,
				login_time: today.format('yyyy-MM-DD H:mm:ss'),
				current_ip: ''
			}
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};
			Axios.post(API_URL + 'admin-login.php', obj, { axiosConfig })
			.then(
				(res) => {
					if(res.data.res_status === "200"){
						ls.set("storeData",JSON.stringify(res.data.res_data));
						this.props.history.push('/admin/dashboard')
					}else{
						this.setState({
							msg: res.data.res_message,
							btnStaus:true,
							btnText: "Login"
						});
					}					
				}
			)
		}
	}

	componentDidMount(){
		document.title = "Admin Login";
	}
	render() {
		const localData = JSON.parse(ls.get("storeData"));
    	if(localData){
    		return <Redirect to="/admin/dashboard" />;
    	}
        return (
        	<div>
	        	<div className="container">
	        		<div className="form-layouts">
	        			<img src={process.env.PUBLIC_URL + '/assets/logo/logo.png'} alt="Conqer" className="login-logo" />
		        		<Form onSubmit={this.onSubmit}>
						    <h2 className="login-label">Login</h2>
						    <span className="errorMsg">{this.state.msg}</span>
						    <div className="form-group">
						    	<label htmlFor="email">Email:</label>
						        <input type="text" className="form-control" id="email" placeholder="Enter email" onChange={this.onChangeEmail} name="email" />
						        <span className="form-error">{this.state.errors["email_error"]}</span>
						    </div>
						    <div className="form-group">
						      	<label htmlFor="pwd">Password:</label>
						      	<input type="password" className="form-control" id="pwd" onChange={this.onChangePassword} placeholder="Enter password" name="password" />
						      	<span className="form-error">{this.state.errors["password_error"]}</span>
						    </div>
						    <Button type="submit" className="btn btn-primary login-btn-admin" disabled={!this.state.btnStaus}>{this.state.btnText}</Button>
						</Form>
					</div>
				</div>
        	</div>
        )
    }
}