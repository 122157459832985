import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import { NavLink, Redirect } from 'react-router-dom';
import DOMPurify from "dompurify";
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const regex = /^[a-z\d\,-_\s]+$/i;
const errors = {};
export default class AddAlbum extends Component {

	constructor(props) {
		super(props);
		this.onChangeAlbumTitle = this.onChangeAlbumTitle.bind(this);
		this.onChangeUploadCover = this.onChangeUploadCover.bind(this);
		this.onChangeAbout = this.onChangeAbout.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			album_title: '',
			uploadcover: null,
			token: ls.get('token'),
			about: '',
			error: null,
			errors: [],
			msg: null,
			successclass: 'hideclass',
			redirect: false,
			filecovererror: '',
			submitbuttontext: 'Save Album',
			selectedcover: 'Choose File',
			loadinng: false,
			emptySSN: false
		}
	}

	componentDidMount() {
		document.title = "CONQER Music - Add Album";
		this.checkSSN(ls.get('userid'));
	}
	checkSSN = (_userID) => {
		Axios.post(base_api_url + 'check-ssn.php', { userid: _userID }).then((res) => {
			if (res.data.res_status == 200) {
				this.setState({
					emptySSN: !res.data.ssn
				});
			}
		});
	}
	handleValidation = () => {
		let formIsValid = true;

		if (!this.state.album_title) {
			formIsValid = false;
			errors["album_title_error"] = "Please enter album title.";
		}

		if (!this.state.uploadcover) {
			formIsValid = false;
			errors["uploadcover_error"] = "Please upload a cover image.";
		}

		this.setState({ errors: errors });
		return formIsValid;

	}

	onChangeUploadCover(e) {
		if (this.state.errors['uploadcover_error']) { delete this.state.errors['uploadcover_error']; }

		if (e.target.files.length > 0) {
			var validExt = ".png, .gif, .jpeg, .jpg";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);
			var maxSize = '10240';
			var fsize = e.target.files[0].size / 1024;
			//console.log(pos);
			if (pos < 0) {
				this.setState({
					filecovererror: 'Please upload JPG or JPEG or PNG or GIF image only.',
					selectedcover: '',
					uploadcover: ''
				});
			} else if (fsize > maxSize) {
				this.setState({
					filecovererror: 'Please upload maximum 10MB.',
					selectedcover: '',
					uploadcover: ''
				});
			} else {
				this.setState({
					filecovererror: '',
					selectedcover: '',
					uploadcover: ''
				});
				this.setState({ selectedcover: e.target.files[0].name, uploadcover: e.target.files[0] });
			}
		} else {
			this.setState({
				filecovererror: '',
				selectedcover: '',
				uploadcover: ''
			});
		}
	}

	onChangeAlbumTitle(e) {
		let value = e.target.value;
		if (this.state.errors['album_title_error']) { delete this.state.errors['album_title_error']; }
		this.setState({ album_title: e.target.value, error: null });
	}
	onChangeAbout(e) {
		this.setState({
			about: e.target.value
		});
	}


	onSubmit(e) {
		e.preventDefault();
		if (this.handleValidation()) {
			const formData = new FormData();
			this.setState({
				loadinng: true
			});
			const usertoken = this.props.match.params.id;
			const axiosConfig = {
				'Content-Type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*",
			};

			if (this.state.uploadcover != null) {
				formData.append("cover", this.state.uploadcover, this.state.uploadcover.name);
			}

			formData.append("album_title", this.state.album_title);
			formData.append("about", this.state.about);
			formData.append("user_id", ls.get('userid'));

			Axios.post(base_api_url + 'add-album.php', formData, { axiosConfig })
				.then(
					(res) => {
						if (res.data.res_status == 200) {
							window.scrollTo(0, 0);
							this.setState({
								successclass: 'successmsg',
								submitbuttontext: 'Save Album',
								msg: 'Album is added successfully!',
								loadinng: false,
								redirect: true,
							});

						} else {
							window.scrollTo(0, 0);
							this.setState({
								successclass: 'errormsg',
								msg: 'Album is not added, please try again!',
								loadinng: false
							})
							setTimeout(() => {
								this.setState({
									successclass: 'hideclass',
									submitbuttontext: 'Save Album',
									msg: null,
								});
							}, 10000)
						}
					}
				);
			this.setState({
				album_title: '',
				uploadcover: '',
				submitbuttontext: 'Save Album',
				about: '',
				selectedcover: 'Choose File',
			});
		}
	}

	render() {
		if (this.state.loadinng === true) {
			return <Spinner />;
		}
		if (this.state.redirect == true) {
			let redurl = '/my-albums/' + this.state.token;
			return <Redirect to={{
				pathname: redurl,
				state: { message: this.state.msg }
			}} />
		} else {
			return (
				<React.Fragment>
					{(this.state.emptySSN) ? <div class="alert alert-warning" role="alert">Please update SSN number in your profile. This enables you to receive payments and tax documents at the year end.</div> : ""}
					<div className={this.state.successclass}>{this.state.msg}</div>
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
									<h3 className="box-title">Albums</h3>
									<div className="box-tools">
										<NavLink to={'/my-albums/' + this.state.token} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</NavLink>

									</div>
								</div>
								<div className="box-body no-padding">
									<form className="artist-form" onSubmit={this.onSubmit}>
										<div className="row">
											<div className="col-md-12">
												<div className="sectionhead">
													<div className="headsec">
														<h3>Album Information</h3>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label htmlFor="albumtitle">Title<span className="requiredastrik">*</span></label>
													<input type="text" value={this.state.album_title} onChange={this.onChangeAlbumTitle} className="form-control" />
													<span className="errorMsg">{this.state.error}</span>
													<font className="errorMsg">{this.state.errors["album_title_error"]}</font>
												</div>
											</div>

										</div>

										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label htmlFor="coverpic">Upload Cover<span className="requiredastrik">*</span></label>
													<input type="file" className="form-control" id="coverpic" onChange={this.onChangeUploadCover} accept="image/*" />
													<div><span className="wrongpassmsg">Note: Supported type is JPG, JPEG, PNG, GIF and maximum file size is 10MB .</span></div>
													<font className="errorMsg">{this.state.errors["uploadcover_error"]}</font>
													<font className="errorMsg">{this.state.filecovererror}</font>
												</div>
											</div>

										</div>

										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<label htmlFor="about">About</label>
													<textarea className="form-control rounded-0" id="about" onChange={this.onChangeAbout} rows="3"></textarea>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 notmainbtn">
												<button type="submit" className="btn btn-primary">{this.state.submitbuttontext}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		}
	}
}