import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import { API_URL } from '../config/constants.js';
import $ from 'jquery';
const base_api_url = API_URL;
export default class News extends Component {
    
	constructor(props){
		super(props);		
		this.state = {
		  urlvalinitial:null,
		  maintitle:null,
		  mainlink:null,
		  newsdata: [],
		  maintitle_second:null,
		  mainlink_second:null,
		  newsdata_second: [],
		  maintitle_third:null,
		  mainlink_third:null,
		  newsdata_third: [],
		  maintitle_fourth:null,
		  mainlink_fourth:null,
		  newsdata_fourth: []
		}		
	}
		
	componentDidMount(){
		document.title = "CONQER Music - News + Media";
		this.firstNewBlockData();
		this.secondNewBlockData();
		this.thirdNewBlockData();
		this.fourthNewBlockData();
	}
	
	firstNewBlockData = () => {
		Axios.get(base_api_url+'news-amen-worldwide.php')
			.then(res => {
				
				this.setState({
					maintitle: res.data.main_link.title,
					mainlink: res.data.main_link.link,
					newsdata: res.data.other_link
				});
		})
	}
	
	secondNewBlockData = () => {
		Axios.get(base_api_url+'news-release-today.php')
			.then(res => {
				console.log(res.data.other_link);
				this.setState({
					maintitle_second: res.data.main_link.title,
					mainlink_second: res.data.main_link.link,
					newsdata_second: res.data.other_link
				});
		})
	}
	
	thirdNewBlockData = () => {
		Axios.get(base_api_url+'news-biblegateway-blog.php')
			.then(res => {
				
				this.setState({
					maintitle_third: res.data.main_link.title,
					mainlink_third: res.data.main_link.link,
					newsdata_third: res.data.other_link
				});
		})
	}
	
	fourthNewBlockData = () => {
		Axios.get(base_api_url+'news-gospel-coalition.php')
			.then(res => {
				
				this.setState({
					maintitle_fourth: res.data.main_link.title,
					mainlink_fourth: res.data.main_link.link,
					newsdata_fourth: res.data.other_link
				});
		})
	}
	
	onclickseturl = (urlval) => {
		this.setState({
			urlvalinitial: urlval
		});
		$('#myModal').modal('show');
	}
	
	openYouTubeLink = (urlval) => {
		window.open(urlval, '_blank', 'noopener,noreferrer');  // Opens YouTube link in a new tab
	}
	
	closemodal = () => {
		this.setState({
			urlvalinitial: ''
		});
		$('#myModal').modal('hide');
	}
	
	render() {
        return (
			<React.Fragment>
				<section className="news-media-top">
				  <div>
					<img src={process.env.PUBLIC_URL + 'assets/news/img_news.jpg'} />
				  </div>
				</section>

				<section className="news-section">
				  <div className="container" >
					<div className="news-second">
					  <ul>
						<li className="even lastItem">
						   <NavLink to={'/32-conquer-everything-coming-soon'} className="newscoming">Conquer Everything Coming Soon</NavLink>
						   <div className="ItemIntrotext">
							 <a className="moduleItemImage" href="#" title="Continue reading &quot;Conquer Everything Coming Soon&quot;">
							  <img src={process.env.PUBLIC_URL + 'assets/news/news-conqer.jpg'} alt="Conquer Everything Coming Soon" />
							 </a>

							 <p>Gospel artist Michael Anderson Jr is set to release his seventh studio album titled "Conquer Everything" {/*on Jan 25<sup>th</sup> */}exclusively on Conqer Music. Make sure to check out his body of work with such titles as Revival, Who Am I, wAKE uP, The Clarion Call, 12.10.79, and Don't Say Much Let God Speak.</p>

							 <p>
							  <a href="https://open.spotify.com/artist/1xQ6GA0A4RnrE6ZrIRyqi4?si=_hoI75tfS5GDdetmWCzF0Q&nd=1" target="_blank" rel="noopener noreferrer">Spotify</a> - 
							  <a href="https://www.youtube.com/channel/UCvuJKYqvTFPU_jxNGU8iHQQ" target="_blank" rel="noopener noreferrer">YouTube</a> - 
							  <a href="https://www.facebook.com/WhoIsMichaelAndersonJr" target="_blank" rel="noopener noreferrer">Facebook Who is Michael</a> - 
							  <a href="https://www.instagram.com/michaelandersonjr/" target="_blank" rel="noopener noreferrer">Instagram Michael</a> - 
							  <a href="https://www.instagram.com/RevivalDaAlbum/" target="_blank" rel="noopener noreferrer">Instagram Revival Album</a> - 
							  <a href="https://twitter.com/prolificmikejr" target="_blank" rel="noopener noreferrer">Twitter</a>
							 </p>

							 <p>Mr. Anderson is a sought-after prolific, gifted performer, writer and producer with over 20 years of professional experience in the entertainment industry with an emphasis on music production. He is trusted by artists as well as other entertainment professionals to produce the best overall project.</p>

							 <p>&nbsp;</p>

							 <div className="clr"></div>

							 <span className="ItemDateCreated">Written on %AM, %14 %423 %2019 %09:%Oct</span> in <NavLink to={'/5-conqer'} className="ItemCategory">CONQER</NavLink><span className="ItemHits"> Read 424 times </span>
							 <div className="clr"></div>

						   </div>
						</li>
						<li className="clearList"></li>
					  </ul>
					</div>
				  </div>
				</section>

				<section className="news-section">
				  <div className="container">
					<div className="news-third">
					  <div className="row">
						<div className="col-md-6">
						  <div className="news-links">
							<h4><a href="javascript:;" title={this.state.maintitle} onClick={() => this.openYouTubeLink(this.state.mainlink)} >{this.state.maintitle}</a></h4>
							<ul>
							  {this.state.newsdata.map((result) => {
								return (
								  <li>
								  <a href="javascript:;" title={result.title} className="lightbox" onClick={() => this.openYouTubeLink(result.url)}>{result.title}</a>
								  </li>
							  )
							  })}							  
							</ul>
						  </div>
						</div>
						<div className="col-md-6">
						  <div className="news-links">
							<h4><a href="javascript:;" title={this.state.maintitle_second} className="lightbox" onClick={() => this.onclickseturl(this.state.mainlink_second)}>{this.state.maintitle_second}</a></h4>
						   <ul>
							{this.state.newsdata_second.map((result) => {
							return (
								<li>
								<a href="javascript:;" title={result.title} className="lightbox" onClick={() => this.onclickseturl(result.url)}>{result.title}</a>
								</li>
								)
							})}							
						   </ul>  
						  </div>
						</div>
					  </div>
					  <div className="row bottom-media">
						<div className="col-md-6">
						  <div className="news-links">
							<h4><a href="javascript:;" title={this.state.maintitle_third} className="lightbox" onClick={() => this.onclickseturl(this.state.mainlink_third)}>Bible Gateway Blog</a></h4>
							<ul>
							  {this.state.newsdata_third.map((result3) => {
								  console.log('result',result3);
								return (
									<li>
										<a href="javascript:;" title={result3.title} className="lightbox" onClick={() => this.onclickseturl(result3.url)}>{result3.title}</a>
									</li>
									)
								})}
							  
							</ul>
						  </div>
						</div>
						<div className="col-md-6">
						  <div className="news-links">
							<h4><a href="javascript:;" title={this.state.maintitle_fourth} className="lightbox" onClick={() => this.onclickseturl(this.state.mainlink_fourth)}>{this.state.maintitle_fourth}</a></h4>
							<ul>
							  {this.state.newsdata_fourth.map((result) => {
								return (
								  <li>
									<a href="javascript:;" title={result.title} className="lightbox" onClick={() => this.onclickseturl(result.url)}>{result.title}</a>
								  </li>
								)
							  })}							  
							</ul>   
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</section>

				<div className="news-media modal fade" id="myModal" data-easein="expandIn">
					<div className="modal-dialog">
					  <div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" onClick={() => this.closemodal()}>&times;</button>							
						</div>
						<iframe src={this.state.urlvalinitial} width="100%" height="100%"></iframe>
						
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
						</div>
					  </div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}