import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, NavLink, Redirect } from 'react-router-dom';
import ls from 'local-storage';
export default class Header extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const localData = JSON.parse(ls.get('storeData'));
    if (localData === null) {
      return <Redirect to="/admin/logout" />;
    }
    return (
    
        <div className="main-sidemenu" id="sidebar">
          <div className="logo-menu">
            <NavLink to="#" className="">
              <img src={process.env.PUBLIC_URL + '/assets/logo/logo.png'} alt="Conqer" />
            </NavLink>
          </div>
          <div className="menuitems">
            <ul className="mainmenutims">
              <li>
                <NavLink to="/admin/dashboard">
                  <i className="fa fa-dashboard"></i> Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/events">
                  <i className="fa fa-calendar"></i> Events
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/users">
                  <i className="fa fa-users"></i> Users
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/celebrity">
                  <i className="fa fa-user"></i> Celebrity
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/church">
                  <img className="church-icons" src={process.env.PUBLIC_URL + '/assets/logo/church-1.png'} alt="" /> Church
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/songs">
                  <i className="fa fa-music"></i> Songs
                </NavLink>
              </li>
        <li>
                <NavLink to="/admin/prayerline">
                  <i className="fa fa-comment"></i> Prayer Line
                </NavLink>
              </li>
        <li className="dropdown open">
                <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-money"></i> Reports <i className="fa fa-angle-right right rightanle"></i>
                </a>
                <ul className="dropdown-menu forAnimate" role="menu">
          <li>
                    <NavLink to="/admin/adminpayment">Admin's Revenue</NavLink>
                  </li>
          <li>
                    <NavLink to="/admin/userpayment">Artist's Revenue</NavLink>
                  </li>
          <li>
                    <NavLink to="/admin/songrevenue">Songs Revenue</NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/song-played-reports">Songs Playback</NavLink>
                  </li>
                </ul>
              </li>
        <li className="dropdown open">
                <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-university"></i> Masters <i className="fa fa-angle-right right rightanle"></i>
                </a>
                <ul className="dropdown-menu forAnimate" role="menu">
                  <li>
                    <NavLink to="/admin/commission">Commission</NavLink>
                  </li>
          <li>
          <NavLink to="/admin/event-category">Event Category</NavLink>
          </li>
          <li>
          <NavLink to="/admin/genre">Genre</NavLink>
          </li>
          <li>
          <NavLink to="/admin/subgenre">Sub Genre</NavLink>
          </li>
          <li>
                    <NavLink to="/admin/isrc">ISRC</NavLink>
                  </li>
          <li>
                    <NavLink to="/admin/isrcpackage">ISRC Package</NavLink>
                  </li>
          <li>
                    <NavLink to="/admin/notification">Notifications</NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/email-notification">Email</NavLink>
                  </li>
                </ul>
              </li>
        <li>
                <NavLink to="/admin/settings">
                  <i className="fa fa-cog"></i> Settings
                </NavLink>
              </li>
               <li>
                <NavLink to="/admin/suggest-artist">
                  <i className="fa fa-user-plus"></i> Suggested Artist
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/logout">
                  <i className="fa fa-sign-out"></i> Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
     
    );
  }
}
