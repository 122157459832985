import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import  { Link  } from 'react-router-dom';
import ls from 'local-storage';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const base_api_url = API_URL;
toast.configure();
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
const errors = {};
export default class PlayListModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  playListData: '',
		  added: false,
		  playListId: '',
		  showaddplform: 'hidden',
		  oldForm: 'visible',
		  createBtn: 'btn btn-primary visible',
		  commonHide: '',
		  errors:[]
		};
	}

	componentDidMount() {
		this.getPlaylistData();
	}
	notify = () => toast('Song Added Successfully');
	addSong = async (songId, playListId) => {
		const obj = {
			song_id: songId,
			play_list_id: playListId,
			device_id: ''
		};

		const response = await Axios.post(base_api_url + 'addplaylistsongs.php', obj, axiosConfig);
		if (response.data.res_status === '200') {
			toast.success('Song added successfully');
			this.props.handleModal(!this.props.show);
		} else {
			toast.error('Song Already Exist');
			this.props.handleModal(!this.props.show);
		}
		this.setState({
			showaddplform: 'hidden',
			oldForm: 'visible',
			createBtn: 'btn btn-primary visible',
			commonHide: ''
		})
		this.getPlaylistData();
	};

	getPlaylistData = async () => {
		const obj = {
		  userid: ls.get('userid')
		};
		const response = await Axios.post(base_api_url + 'getplaylist.php', obj, axiosConfig);
		if (response.data.res_status === '200') {
		  this.setState({
			playListData: response.data.res_data
		  });
		}
	};
	
	onChangeTitle(e) {
		if(this.state.errors['title_error']){delete this.state.errors['title_error'];}    
		this.setState({
		  title: e.target.value
		});
		}
		playListIsPublic(e){
		this.setState({
		  is_public: e.target.value
		});
		}
		handleValidation = () => {
		let formIsValid = true;
		if(!this.state.title){
		  formIsValid = false;
		  errors["title_error"] = "Please enter title.";
		}
		this.setState({errors: errors});
		return formIsValid;
	}
	async onSubmit() {
		if(this.handleValidation()){
		  const obj = {
			title: this.state.title,
			description: this.state.description,
			is_public: this.state.is_public,
			device_id: '',
			userid: ls.get('userid'),
		  };
		  if (this.state.title !== '') {
			const response = await Axios.post(base_api_url + 'createplaylist.php', obj, axiosConfig);

			if (response.data.res_status === '200') {
				this.setState({ successclass: 'successmsg', msg: 'Playlist created Successfully' }, () => {
					this.addSong(this.props.songId,response.data.playlist_id);
				});
			} else {
				this.setState({ successclass: 'errormsg', msg: 'Playlist Not Created, Please try again.' });
			}
		  } else {
			this.setState({ successclass: 'errormsg', msg: 'Enter data in all Fields.' }, () => {
			  setTimeout(() => {
				this.setState({ successclass: 'hideclass' });
			  }, 3000);
			});
		  }
		}
	}
	onClickNewPlaylist(){
		this.setState({
			oldForm: 'hidden',
			commonHide: 'hidden',
			showaddplform:'visible',
			createBtn: 'btn btn-primary hidden',
		})
	}
	
  render() {
   let userid = ls.get('userid');
   return (
      <>
        {this.state.playListData.length > 0 ? (
          <Modal show={this.props.show}>
            <Modal.Header>
              <Modal.Title>Add Song in the Playlist</Modal.Title>
            </Modal.Header>

            <Modal.Body className="d-flex justify-content-center">
				<Form.Control as="select" onChange={(e) => this.setState({ playListId: e.target.value })} className={this.state.oldForm}>
					<option value="">Select Playlist</option>
					{this.state.playListData.map((item, index) => (
						(userid == item.user_id)?
						<option key={item.id} value={item.id}>
							{item.title}
						</option>:""
					))}
				</Form.Control>
				<section className={this.state.showaddplform} >
					<form name="event-details" onSubmit={this.onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Title<span className="requiredastrik">*</span></label>
									<input
										type="text"
										className="form-control"
										placeholder="Title"
										name="eventtitle"
										id="eventtitle"
										value={this.state.title}
										onChange={this.onChangeTitle.bind(this)}
									/>
									<font className="errorMsg">{this.state.errors["title_error"]}</font>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label>Description</label>
									<input
										type="text"
										value={this.state.description}
										placeholder="Description"
										className="form-control"
										onChange={(e) => this.setState({ description: e.target.value })}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label>Is Public?</label>
									<select className="custom-select" name="genre" id="genre" onChange={this.playListIsPublic.bind(this)}>
										<option value="no">No</option>
										<option value="yes">Yes</option>                   
									</select>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<Button onClick={() => this.onSubmit()} style={{ backgroundColor: '#b8a25e' }}>Add Song</Button> &nbsp;
									<button onClick={() => this.props.handleModal(!this.props.show)} className="btn btn-secondary">Close</button>
								</div>
							</div>
						</div>
					</form>
				</section>
            </Modal.Body>
            <Modal.Footer className={this.state.commonHide}>
				<Button onClick={() => this.addSong(this.props.songId, this.state.playListId)} style={{ backgroundColor: '#b8a25e' }}>Add Song</Button>
				<Link style={{"position": "absolute","left": "0px"}} onClick={() => this.onClickNewPlaylist()} className={this.state.createBtn} ><img className="asd" style={{"width":"22px"}} src="../assets/music/whiteplaylistin.png" /> Create Playlist</Link>
				<button onClick={() => this.props.handleModal(!this.props.show)} className="btn btn-secondary">Close</button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={this.props.show}>
            <Modal.Body>
				<p className={this.state.oldForm}>You don't have any playlists, Please create the playlist first. </p>
				<section className={this.state.showaddplform} >
					<form name="event-details" onSubmit={this.onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Title<span className="requiredastrik">*</span></label>
									<input
										type="text"
										className="form-control"
										placeholder="Title"
										name="eventtitle"
										id="eventtitle"
										value={this.state.title}
										onChange={this.onChangeTitle.bind(this)}
									/>
									<font className="errorMsg">{this.state.errors["title_error"]}</font>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label>Description</label>
									<input
										type="text"
										value={this.state.description}
										placeholder="Description"
										className="form-control"
										onChange={(e) => this.setState({ description: e.target.value })}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label>Is Public?</label>
									<select className="custom-select" name="genre" id="genre" onChange={this.playListIsPublic.bind(this)}>
										<option value="no">No</option>
										<option value="yes">Yes</option>                   
									</select>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<Button onClick={() => this.onSubmit()} style={{ backgroundColor: '#b8a25e' }}>Add Song</Button> &nbsp;
									<button onClick={() => this.props.handleModal(!this.props.show)} className="btn btn-secondary">Close</button>
								</div>
							</div>
						</div>
					</form>
				</section>
			</Modal.Body>
            <Modal.Footer className={this.state.commonHide}>
				<Link style={{"position": "absolute","left": "0px"}} onClick={() => this.onClickNewPlaylist()} className={this.state.createBtn}>
					<img className="asd" style={{"width":"22px"}} src="../assets/music/whiteplaylistin.png" /> Create Playlist
				</Link>
				<button onClick={() => this.props.handleModal(!this.props.show)} className="btn btn-secondary">
					Close
				</button>
            </Modal.Footer>
          </Modal>
        )}		
      </>
    );
  }
}
