import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import Spinner from '../includes/Spinner.component';
import ls from 'local-storage';
import { Link, NavLink } from 'react-router-dom';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';

const base_api_url = API_URL;

const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
export default class ListenerFollowers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      follower: [],
      token: '',
      error: null,
      msg: null,
      loading: true,
      redirect: false
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - My Following';

    const email 	= ls.get('email');
    const name 		= ls.get('name');
    const token 	= ls.get('token');
    const userid 	= ls.get('userid');

    this.setState({
      email: email,
      name: name,
      token: token,
      userid: userid
    });

    this.getAllFollowers();
  }
  componentDidUpdate(){
    $('#myevents').DataTable(
      { 
        destroy: true,
        pageLength: 10,
        order: [[1, 'desc']],
        lengthMenu: [
          [5, 10, 20, 50, -1],
          [5, 10, 20, 50, 'All'],
        ],
        "language": {
          "search": "Search",
          "sEmptyTable": "No Event Found"
        },
        "aaSorting": [],
        "order": [],
        "lengthMenu": [[5, 10, 15, -1], [5, 10, 15, "All"]],
        columnDefs: [{ "targets": 0, "width": "50"},{ orderable: false, targets: -1 },{ orderable: false, targets: 0 }],
      }
    );
  }
  getAllFollowers = async () => {
    const obj = {
      listenerid: ls.get('userid')
    };
   
    const response = await Axios.post(base_api_url + 'listenerfollowers.php', obj, axiosConfig);
   
    $('#myfollowers').DataTable().destroy();
    setTimeout(() => {
      $('#myfollowers').DataTable({
        destroy: true,
        pageLength: 10,
        order: [[1, 'desc']],
        lengthMenu: [
          [5, 10, 20, 50, -1],
          [5, 10, 20, 50, 'All'],
        ],
		language: {
		  emptyTable: "No data found!"
		},
        columnDefs: [{ "targets": 0, "width": "50"},{ orderable: false, targets: -1 },{ orderable: false, targets: 0 }],
      });
    }, 500);
   
      if (response.data.res_status == 200) {
        this.setState({
          follower: response.data.res_data,
          loading: false
        });
      } else {
        this.setState({
          follower: [],
          loading: false
        });
      }
   
  };
  unFollowArtist = (artistID) => {
    if (window.confirm('Do you want to unfollow the artist?')) {
    const listenerobj = {
      artistid:artistID,
      followstatus:true,
      listenerid:ls.get('userid')
    }
    Axios.post(base_api_url+'changefollowstatus.php',listenerobj,{axiosConfig})
    .then((followresults) => {
        if(followresults.data.res_status==200){
          this.setState({
            successclass:'successmsg',
            msg: "Artist "+ (followresults.data.res_message).toLowerCase()            
          });
          this.getAllFollowers();
        }else{
          this.setState({
            successclass:'errormsg',            
            msg:followresults.data.res_message        
          })
        }
       // this.getAllFollowers();
        setTimeout(() => {
          this.setState({
          successclass:'hideclass',
          msg:null
          });
        }, 10000)
      });
    }
  };
  render() {
    if (this.state.loading == true) {
      return <Spinner />;
    }
    console.log(this.state.follower);
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
          <div className={this.state.successclass}>{this.state.msg}</div>
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Following</h3>
              </div>
              <div className="box-body table-responsive no-padding">
                <table id="myfollowers" className="table table-striped table-bordered table-sm row-border hover mb-5">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Artist Name</th>
                      <th>Email</th>
                      <th>Follow Since</th>
                      <th className="actionbtns" style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.follower.map((result, index) => {
                      return (
                        <tr key={index}>
                          <td><img src={result.thumb_profile_pic} className="img-responsive " onError={this.addDefaultSrc} style={{'width':'80px','height':'80px'}}/></td>
                          <td>{result.name}</td>
                          <td>{result.email}</td>
                          <td>{result.date}</td>
                          <td className="actionbtns d-flex justify-content-center">
                            <Link
                                onClick = {()=>this.unFollowArtist(result.artistid)}
                                className="btn btn-info custombtn"
                                title="Unfollow"
                              >
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
