import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import InputMask from 'react-input-mask';
const base_api_url = API_URL;
const errors = {};
export default class Studio extends Component {
	
	constructor(props){
		super(props);
		this.onChangeName = this.onChangeName.bind(this);		
		this.onChangeArtistName = this.onChangeArtistName.bind(this);
		this.onChangePhone = this.onChangePhone.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeService = this.onChangeService.bind(this);
		this.onChangeGenre = this.onChangeGenre.bind(this);
		this.onChangeContactMethod = this.onChangeContactMethod.bind(this);
		this.onChangeNotes = this.onChangeNotes.bind(this);
		this.onChangeTime = this.onChangeTime.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
				
		this.state = {
			name: "",
			email: "",
			artist: "",
			phone: "",
			service: "",
			genre: "",
			contact: "",
			comment: "",
			time: "",
			error: "",
			msg: "",
			successclass: 'hideclass',
			submitbuttontext: 'Submit',
			redirect: false,
			errors: [],
		}		
	}	
	
	componentDidMount(){
		document.title = "CONQER Music - Conqer Studio";
	}
	
	onChangeName(e){
		if(this.state.errors['fullname']){delete this.state.errors['fullname'];}
		this.setState({
		  name: e.target.value
		});
	}
	
	onChangeArtistName(e){
		this.setState({
		  artist: e.target.value
		});
	}
	
	onChangePhone(e){
		if(this.state.errors['phone']){delete this.state.errors['phone'];}
		this.setState({
		  phone: e.target.value
		});
	}
	
	onChangeEmail(e){
		if(this.state.errors['email']){delete this.state.errors['email'];}
		this.setState({
		  email: e.target.value
		});
	}
	validateEmail(email) {
	    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
	}
	
	onChangeService(e){
		if(this.state.errors['service']){delete this.state.errors['service'];}
		this.setState({
		  service: e.target.value
		});
	}
	
	onChangeGenre(e){
		if(this.state.errors['genre']){delete this.state.errors['genre'];}
		this.setState({
		  genre: e.target.value
		});
	}
	
	onChangeContactMethod(e){
		if(this.state.errors['contactmethod']){delete this.state.errors['contactmethod'];}
		this.setState({
		  contact: e.target.value
		});
	}
	
	onChangeNotes(e){
		this.setState({
		  comment: e.target.value
		});
	}
	
	onChangeTime(e){
		if(this.state.errors['timetocontact']){delete this.state.errors['timetocontact'];}
		this.setState({
		  time: e.target.value
		});
	}
	
	handleValidation = () => {
		let formIsValid = true;
		if(!this.state.name){
			formIsValid = false;
			errors["fullname"] = "Please enter fullname.";
		}

		if(!this.state.email){
			formIsValid = false;
			errors["email"] = "Please enter email.";
		}else if(!this.validateEmail(this.state.email)){
			formIsValid = false;
			errors["email"] = "Please enter valid email.";
		}
		
		if(!this.state.phone){
			formIsValid = false;
			errors["phone"] = "Please enter phone number.";
		}
		if(!this.state.service){
			formIsValid = false;
			errors["service"] = "Please choose any service.";
		}
		if(!this.state.genre){
			formIsValid = false;
			errors["genre"] = "Please choose any genre.";
		}
		if(!this.state.time){
			formIsValid = false;
			errors["timetocontact"] = "Please enter time to contact.";
		}
		if(!this.state.contact){
			formIsValid = false;
			errors["contactmethod"] = "Please choose any contact method.";
		}

		this.setState({errors: errors});
		return formIsValid;

	}
	
	onSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			const formData = new FormData();
			const axiosConfig = {
				  'Content-Type': 'multipart/form-data',
				  "Access-Control-Allow-Origin": "*",
			};
			this.setState({
				submitbuttontext: 'Processing...',
			});
			formData.append("name", this.state.name);
			formData.append("email", this.state.email);
			formData.append("artist", this.state.artist);
			formData.append("phone", this.state.phone);
			formData.append("service", this.state.service);
			formData.append("genre", this.state.genre);
			formData.append("contact", this.state.contact);
			formData.append("comment", this.state.comment);
			formData.append("time", this.state.time);
			
			Axios.post(base_api_url+'send-studio-message.php', formData, {axiosConfig})
			.then(
				(res) => {console.log(res.data);
					if(res.data.res_status==200){
						window.scrollTo(0, 3000);
						this.setState({
							successclass:'successmsg',
							submitbuttontext : 'Submit',
							msg:'Your message is sent successfully!',
							name:"",
							email:"",
							artist:"",
							phone:"",
							service:"",
							genre:"",
							contact:"",
							comment:"",
							time:""
						});						
						
					}else{
						window.scrollTo(0, 3000);
						this.setState({
							successclass:'errormsg',
							submitbuttontext : 'Submit',						
							msg:'Message is not send, please try again!'
						})
					}
					setTimeout(() => {
					  this.setState({
						successclass:'hideclass',
						msg:null
					  });
					}, 5000)
				}
			);
			
			
		}
	}
	
	
	render() {
        return (
			<React.Fragment>
				<div  className="studio-slider">
					<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
					  <div className="carousel-inner">
						<div className="carousel-item active">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/IMG_0233.jpg'} alt="..." />
						  <div className="title title-studio">
							<h1 className="animated slideInDown">CONQER<span> STUDIO</span></h1>
						  </div> 
						</div>
						<div className="carousel-item">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/IMG_0233.jpg'} alt="..." />
						  <div className="title title-studio">
							<h1 className="animated slideInDown">CONQER <span> STUDIO</span></h1>
						  </div>  
						</div>
					  </div>
					</div>
				</div>

				<section className="about-studio">
				  <div className="container">
					<div className="row">
					  <div className="col-md-2"></div>
					  <div className="col-md-8">
						<div className="text-center">
						  <h2>ABOUT THE STUDIO</h2>
						  <p>Conqer Studio is an all analog set up equipped with Protools, Pro MPA 2 Pre Amp, DBX 266xs Compressor, Big Knob studio interface, Power conditioning distribution system, KRK Rokit 8 and KRK Rokit 5 monitors, and AKG Microphone.</p>
						  <p>We can write, produce, engineer and mix your music in house.</p>
						</div>
					  </div>
					</div>
				  </div>
				</section>

				<section className="studio-service">
				  <div className="container ">
					<div className="service-studio">
					  <h3>STUDIO SERVICES</h3>
					</div>
					<div className="row">
					  <div className="col-md-6">
						<div className="card studiocard">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/IMG_0233.jpg'} />
						  <div className="card-details">
							<h3>HOURLY RECORDING</h3>
							<p>Recording of your song or album. Call now for pricing.</p>
						  </div>
						  <a href="#contactform">Contact Us</a>
						</div>
					  </div>
					  <div className="col-md-6">
						<div className="card studiocard">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/DSC00562.jpg'} />
						  <div className="card-details">
							<h3>MIXING AND MASTERING</h3>
							<p>Get your music ready for the world to hear. Call now for pricing</p>
						  </div>
						  <a href="#contactform">Contact Us</a>
						</div>
					  </div>
					</div>
					<div className="row">
					  <div className="col-md-6">
						<div className="card studiocard">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/IMG_0234.jpg'} />
						  <div className="card-details">
							<h3>HOOK MASTERS</h3>
							<p>Need someone to write and or sing on your song, we got you covered. Call now for pricing</p>
						  </div>
						  <a href="#contactform">Contact Us</a>
						</div>
					  </div>
					  <div className="col-md-6">
						<div className="card studiocard">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/img_1633_cropped.jpg'} />
						  <div className="card-details">
							<h3>MUSIC VIDEO</h3>
							<p>Want to get a music video done for your single lets us complete your project with a YouTube/ VEVO ready visual. Call now for pricing</p>
						  </div>
						  <a href="#contactform">Contact Us</a>
						</div>
					  </div>
					</div>
					<div className="row">
					  <div className="col-md-3"></div>
					  <div className="col-md-6">
						<div className="card studiocard">
						  <img src={process.env.PUBLIC_URL + 'assets/studio/IMG_0232.jpg'} />
						  <div className="card-details">
							<h3>JUST CONQER</h3>
							<p>Use Conqer Studio but bring your own Engineer. Call now for pricing</p>
						  </div>
						  <a href="#contactform">Contact Us</a>
						</div>
					  </div>
					</div>
				  </div>
				</section>

				<section className="studio-covid">
				  <div className="container">
					<div className="row">
					  <div className="col-md-3"></div>
					  <div className="col-md-6">
						<div className="covid-detail">
						  <h3>COVID STANDARDS</h3>
						  <p>Temperature check and hand sanitizer at the door. Mask must be worn when not recording. Studio is sanitized after each session.</p>
						</div>
					  </div>
					  <div className="col-md-3"></div>
					</div>
				  </div>
				</section>

				<section className="studio-form" id="contactform">
				  <div className="container">
					<div className={this.state.successclass}>{this.state.msg}</div>
					<div className="studio-contact">
					  <h3>Contact us</h3>
					  <p>Someone will contact you within two calendar days</p>
					</div>
					<div className="row">
					  <div className="col-md-12">
						  <form className="form-contact" onSubmit={this.onSubmit}>
							<div className="form-group">
								<label htmlFor="fullname">Full Name *</label>
								<input type="text" className="form-control" onChange={this.onChangeName}  value={this.state.name} id="fullname" />
								<font className="errorMsg">{this.state.errors["fullname"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="artist">Artist Name (If Applicable)</label>
								<input type="text" className="form-control" id="artist" value={this.state.artist} onChange={this.onChangeArtistName} />
							</div>
							<div className="form-group">
								<label htmlFor="phone">Phone Number *</label>
								<InputMask {...this.props} className="form-control" mask="(999) 999-9999" tabIndex="0" maskChar=" "  value={this.state.phone} onChange={this.onChangePhone} id="phone"  />
								<font className="errorMsg">{this.state.errors["phone"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="email-s">Email *</label>
								<input type="text" className="form-control" onChange={this.onChangeEmail} value={this.state.email} id="email-s" />
								<font className="errorMsg">{this.state.errors["email"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="artist">Which service(s) do you need? *</label>
								<select className="form-control" name="services" value={this.state.service} onChange={this.onChangeService} >
								  <option value="">Select </option>
								  <option value="Hourly Recording">Hourly Recording </option>
								  <option value="Mixing and Mastering">Mixing and Mastering </option>
								  <option value="Hook Masters">Hook Masters </option>
								  <option value="Music Video">Music Video </option>
								  <option value="Just Conqer">Just Conqer </option>
								</select>
								<font className="errorMsg">{this.state.errors["service"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="music">Music Genre *</label>
								<input type="text" name="genre" className="form-control" value={this.state.genre} onChange={this.onChangeGenre} id="music" />
								<font className="errorMsg">{this.state.errors["genre"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="best-time">Best time to contact you *</label>
								<input type="text" name="besttime" className="form-control" value={this.state.time} onChange={this.onChangeTime} id="best-time" />
								<font className="errorMsg">{this.state.errors["timetocontact"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="artist">Preferred method of contact *</label>
								<select className="form-control" name="contactmethod" value={this.state.contact} onChange={this.onChangeContactMethod} >
								  <option value="">Select </option>
								  <option value="phone">Phone</option>
								  <option value="Email">Email</option>
								</select>
								<font className="errorMsg">{this.state.errors["contactmethod"]}</font>
							</div>
							<div className="form-group">
								<label htmlFor="comment">Additional Notes </label>
								<textarea className="form-control" name="comments" value={this.state.comment} onChange={this.onChangeNotes} rows="6" id="comment" placeholder="Describe what you need"></textarea>
						  </div>
						  <div className="form-group q-a-submit">
							<input type="submit" value={this.state.submitbuttontext} name="Submit" />
						  </div>
						  </form>
						</div>
					</div>
				  </div>
				</section>

				<section>
				  <div className="container-fluid">
					<div className="address-studio">
					  <div className="row">
						<div className="col-md-6">
						  <div className="lefteaddress">
							<h3>STUDIO ADDRESS</h3>
							<p>21704 Golden Triangle Road, Suite 416 <br/> Santa Clarita, CA 91350</p>
						  </div>
						</div>
						<div className="col-md-6">
						  <div className="rightaddress">
							<h3>FOLLOW US</h3>
							<div className="socicalmedia-studio">
							  <div className="cansocial-s">
								<a target="_blank" href="https://www.facebook.com/Conqer-Music-109539370459926/" className="can-social-link">
									<span className="can-social-icon"><i className="fa fa-facebook fa-4x"></i></span>
								</a>
							  </div>
							  <div className="cansocial-s">
								<a target="_blank" href="https://twitter.com/ConqerM?s=09" className="can-social-link">
									<span className="can-social-icon"><i className="fa fa-twitter fa-4x"></i></span>
								</a>
							  </div>
							  <div className="cansocial-s">
								<a target="_blank" href="https://www.instagram.com/conqermusic/" className="can-social-link">
									<span className="can-social-icon"><i className="fa fa-instagram fa-4x"></i></span>
								</a>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</section>
			</React.Fragment>
		)
	}
}