import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import  { Link, NavLink, Redirect } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import InputMask from 'react-input-mask';
import $ from 'jquery'; 
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
const errors = {};
toast.configure();
export default class Index extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		errors:[],
    		name: '',
    		email:'',
    		mobile:'',
    		btnDisable:false,
    		btnText: "Submit",
    		formValid: true,
    		redirect: false,
    		ref_code:'',
    		photo_id: 0,
    		photo_path:'',
			typeOfPage:"Add",
    	}
    }
    componentDidMount() {
	  	document.title = "CONQER Music - Add Celebrity";
		const c_id = this.props.match.params.id;
		if(c_id){
			this.getCelebrityDetail(c_id);
			this.setState({
				btnText: "Update",
				typeOfPage:"Edit",
			});
			document.title = "CONQER Music - Edit Celebrity";
		}
	}
	getCelebrityDetail = (id) => {
		let formData = {
			"id":id
		}
		Axios.post(base_api_url + 'celebrity-detail.php',formData, {axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	            	this.setState({
	            		id: res.data.res_data.id,
	              		name: res.data.res_data.name,
	              		email: res.data.res_data.email,
	              		mobile: res.data.res_data.phone,
	              		ref_code : res.data.res_data.referral_code,
	              		photo_id : res.data.res_data.photo_id,
	              		photo_path : res.data.res_data.photo_path
	            	});
	          	}
	        }
	    );
	}
	onChangeName(e) {
		if (this.state.errors['name_error']) {delete this.state.errors['name_error'];}
		this.setState({
			name: e.target.value
		})
	}
	onChangeEmail(e) {
		if (this.state.errors['email_error']) {delete this.state.errors['email_error'];}
		this.setState({
			email: e.target.value
		})
	}
	onChangeMobile(e) {
		if (this.state.errors['mobile_error']) {delete this.state.errors['mobile_error'];}
		this.setState({
			mobile: e.target.value
		})
	}
	onFileChange(e) {
		this.setState({formValid:true});
		if (this.state.errors['photo_error']) {
			delete this.state.errors['photo_error'];
		}
		if( e.target.files.length > 0 ){
			var validExt = ".png, .jpeg, .jpg";
			var filePath = e.target.files[0].name;
			var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			var pos = validExt.indexOf(getFileExt);      
			if(pos < 0){
				errors['photo_error'] = "Sorry, " + filePath + " is invalid, allowed extensions are: " + validExt;
				this.setState({ profileFile: e.target.files[0], formValid: false });
			}else{
				this.setState({ profileFile: e.target.files[0], formValid: true, photo_id: 0});
			}	
		}
	}
	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	insertData(){
		const formData = new FormData();
	    if (this.state.profileFile != null) {
	        formData.append('profileFile', this.state.profileFile, this.state.profileFile.name);
	    }
	    formData.append('name', this.state.name);
  		formData.append('email', this.state.email);
  		formData.append('mobile', this.state.mobile);
  		Axios.post(base_api_url + 'manage-celebrity.php', formData, { axiosConfig }).then((res) => {
  			if (res.data.res_status == 200) {
  				toast.success(res.data.res_message);
  				this.setState({
  					redirect:true
  				});      				
  			}else{
  				toast.error(res.data.res_message);
  			}
  			this.setState({
		        btnDisable: false,
		        btnText: 'Submit'
		    });
  		});
	}
	updateData(){
		const formData = new FormData();
	    if (this.state.profileFile != null) {
	        formData.append('profileFile', this.state.profileFile, this.state.profileFile.name);
	    }
	    formData.append('id', this.state.id);
	    formData.append('name', this.state.name);
  		formData.append('email', this.state.email);
  		formData.append('mobile', this.state.mobile);
  		formData.append('ref_code', this.state.ref_code);
  		formData.append('photo_id', this.state.photo_id);
  		Axios.post(base_api_url + 'manage-celebrity.php', formData, { axiosConfig }).then((res) => {
  			if (res.data.res_status == 200) {
  				toast.success(res.data.res_message);
  				this.setState({
  					redirect:true
  				});      				
  			}else{
  				toast.error(res.data.res_message);
  			}
  			this.setState({
		        btnDisable: false,
		        btnText: 'Update'
		    });
  		});
	}
	async onSubmit(e) {
		e.preventDefault();
		if (this.handleValidation()) {
			this.setState({
		        btnDisable: true,
		        btnText: 'Please Wait...'
		    });
		    if(this.state.id){
		    	this.updateData();
		    }else{
		    	this.insertData();
		    }		    
		}
	}
	handleValidation = () => {
		let formIsValid = true;
		if (!this.state.name) {
		  	formIsValid = false;
		  	errors['name_error'] = 'Please enter name.';
		}
		if(this.state.email && !this.validateEmail(this.state.email)){
			formIsValid = false;      
      		errors["email_error"] = "Please enter valid email.";
		}
		if(!this.state.formValid){
        	formIsValid = false;
      	}
		this.setState({ errors: errors });
    	return formIsValid;
	}
	onclickseturl = () => {
	    $('#myModal').modal('show');
	};
	render() {
		if(this.state.redirect){
			return <Redirect to="/admin/celebrity" />
		}
		return(
		<React.Fragment>
    		<div className="dashboard-wrapper">
    			<AdminHeader />
    			<div className="maincontent-area">
    				<AdminTopHeader />
    				<div className="main-dashsection">
    					<section className="contentpard">
    						<div className="row">
								<div className="col-md-12">
									<div className="box box-success distributions-profileform">
										<div className="box-header with-border addprofilenew">
									  		<h3 className="box-title">{this.state.typeOfPage} Celebrity</h3>
									  		<div className="box-tools">
												<NavLink to={'/admin/celebrity'} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i> Back</NavLink>
											</div>          
										</div>
										<div className="box-body no-padding">
											<div className="row">
												<div className="col-md-12">
													<div className="sectionhead">
														<div className="headsec">
															<h3>Basic Information</h3>
														</div>
													</div>
												</div>
											</div>
											<form className="artist-form" onSubmit={this.onSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-6">
													  	<div className="form-group">
															<label htmlFor="lattempts">Name<span className="requiredastrik">*</span></label>
															<input type="text" placeholder="Enter Name" onChange={this.onChangeName.bind(this)} className="form-control" value={this.state.name} />
															<font className="errorMsg">{this.state.errors['name_error']}</font>
														</div>
													</div>
													<div className="col-md-6">
													  	<div className="form-group">
															<label htmlFor="lattempts">Email</label>
															<input type="text" onChange={this.onChangeEmail.bind(this)} placeholder="Enter Email" className="form-control" value={this.state.email} />
															<font className="errorMsg">{this.state.errors['email_error']}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
													  	<div className="form-group">
															<label htmlFor="lattempts">Mobile</label>
															<InputMask {...this.props} mask="(999) 999-9999" maskChar="" placeholder="Enter Mobile" onChange={this.onChangeMobile.bind(this)} className="form-control" value={this.state.mobile} />
														</div>
													</div>
													<div className="col-md-6">
													  	<div className="form-group">
															<label htmlFor="lattempts">Profile Photo </label>&nbsp; {(this.state.photo_id)? <Link to="#" onClick={() => this.onclickseturl()}>View</Link>:""}
															<input type="file" ref="uploadImageProfile" className="form-control" onChange={this.onFileChange.bind(this)} accept="image/*" />
															<font className="errorMsg">{this.state.errors['photo_error']}</font>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<button type="submit" className="btn btn-primary" disabled={this.state.btnDisable}>
															{this.state.btnText}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
    					</section>
    				</div>
    			</div>
    		</div>
    		<div id="myModal" className="modal fade imgmodal" role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="btn btn-default closeicon" data-dismiss="modal">
							<span aria-hidden="true">&times;</span>
						</button>
						<div className="modal-body">
							<img className="showimage img-responsive" src={this.state.photo_path} />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>);
	}
}