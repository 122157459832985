import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../config/constants.js';
import ls from 'local-storage';
import  { Link, NavLink } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import $ from 'jquery'; 
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
export default class Index extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		detail:'',
    		btnArtist: 'Copy',
    		btnListener: 'Copy'
    	}
    }
    componentDidMount() {
	  	document.title = "CONQER Music - Church Detail";
	  	const c_id = this.props.match.params.id;
	  	if(c_id){
			this.getCelebrityDetail(c_id);
		}
	}
	getCelebrityDetail(id){
		let formData = {
			"id":id
		}
		Axios.post(base_api_url + 'church-detail.php',formData, {axiosConfig}).then(
	        (res) => {
	          	if (res.data.res_status == 200) {
	            	this.setState({
	            		detail: res.data.res_data
	            	});
	          	}
	        }
	    );
	}
	copyToClipboard(type){
		if(type == 'artist'){
			this.textArtist.select();
			document.execCommand('copy');
			this.textArtist.focus();
			this.setState({ btnArtist: 'Copied!', btnListener: 'Copy' });
		}else if(type == 'listener'){
			this.textListener.select();
			document.execCommand('copy');
			this.textListener.focus();
			this.setState({ btnArtist: 'Copy', btnListener: 'Copied!' });
		}		
		
	};
	onclickseturl = () => {
	    $('#myModal').modal('show');
	};
	download = (e,name) => {
	    fetch(e, {
	      method: "GET",
	      headers: {}
	    })
	      .then(response => {
	        response.arrayBuffer().then(function(buffer) {
	          const url = window.URL.createObjectURL(new Blob([buffer]));
	          const link = document.createElement("a");
	          link.href = url;
	          link.setAttribute("download", name+".png"); //or any other extension
	          document.body.appendChild(link);
	          link.click();
	        });
	      })
	      .catch(err => {
	        console.log(err);
	      });
	  };
	render() {
		return(
		<React.Fragment>
    		<div className="dashboard-wrapper">
    			<AdminHeader />
    			<div className="maincontent-area">
    				<AdminTopHeader />
    				<div className="main-dashsection">
    					<section className="contentpard">
    						<div className="row">
								<div className="col-md-12">
									<div className="box box-success distributions-profileform">
										<div className="box-header with-border addprofilenew">
									  		<h3 className="box-title">Church Details</h3>
									  		<div className="box-tools">
												<NavLink to={'/admin/church'} className="btn btn-primary"><i className="fa fa-angle-double-left" aria-hidden="true"></i> Back</NavLink>
											</div>          
										</div>
										<div className="box-body no-padding">
											<div className="row">
												<div className="col-md-12">
													<div className="sectionhead">
														<div className="headsec">
															<h3>Basic Information</h3>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Church Name</label>
														<div className="decentvalue">{this.state.detail.church_name}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Pastor Name</label>
														<div className="decentvalue">{(this.state.detail.paster_name)?this.state.detail.paster_name:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Address</label>
														<div className="decentvalue">{(this.state.detail.city)?this.state.detail.city:'-'}{(this.state.detail.state)?', '+this.state.detail.state:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Attendance</label>
														<div className="decentvalue">{(this.state.detail.attendance)?this.state.detail.attendance:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Denomination</label>
														<div className="decentvalue">{(this.state.detail.denomination)?this.state.detail.denomination:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Multi Site</label>
														<div className="decentvalue">{(this.state.detail.multi_site)?this.state.detail.multi_site:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Email</label>
														<div className="decentvalue">{(this.state.detail.email)?this.state.detail.email:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Mobile</label>
														<div className="decentvalue">{(this.state.detail.phone)?`(${this.state.detail.phone.substring(0, 3)}) ${this.state.detail.phone.substring(3, 6)}-${this.state.detail.phone.substring(6,this.state.detail.phone.length)}`:'-'}</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="form-group">
														<label for="firstname">Referral Code</label>
														<div className="decentvalue">{this.state.detail.referral_code}</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label for="firstname">Link for Artist</label>
														<div className="input-group">
															<input type="text" ref={(textArtist) => this.textArtist = textArtist} className="form-control" value={this.state.detail.artist_link} readonly="" />
															<font class="input-group-addon" onClick={() => this.copyToClipboard('artist')}>{this.state.btnArtist}</font>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Link for Listener</label>
														<div className="input-group">
															<input type="text" className="form-control" ref={(textListener) => this.textListener = textListener} value={this.state.detail.listener_link} readonly="" />
															<font class="input-group-addon" onClick={() => this.copyToClipboard('listener')}>{this.state.btnListener}</font>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label>Artist QR Code</label>
														<div className="input-group">
															{/* <img src={"https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl="+this.state.detail.artist_link+"%2F&choe=UTF-8&chld=H|0"} style={{"height":"240px"}}/> */}
															<img src={"https://api.qrserver.com/v1/create-qr-code/?data=" + encodeURIComponent(this.state.detail.artist_link) + "&size=300x300"} style={{"height":"240px"}}/>
														</div>
														<Link download onClick={() => this.download("https://api.qrserver.com/v1/create-qr-code/?data=" + encodeURIComponent(this.state.detail.artist_link) + "&size=300x300", (this.state.detail.church_name).replaceAll(" ","_")+"_Artist")} title="Download Artist QR Code">Download Artist QR Code
														</Link>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Listener QR Code</label>
														<div className="input-group">
															<img src={"https://api.qrserver.com/v1/create-qr-code/?data=" + encodeURIComponent(this.state.detail.listener_link) + "&size=300x300"} style={{"height":"240px"}}/>
														</div>
														<Link download onClick={() => this.download("https://api.qrserver.com/v1/create-qr-code/?data=" + encodeURIComponent(this.state.detail.listener_link) + "&size=300x300", (this.state.detail.church_name).replaceAll(" ","_")+"_Listener")} title="Download Listener QR Code">Download Listener QR Code
														</Link>
													</div>
												</div>
											</div>
											<div className="row">													
											{(this.state.detail.photo_id)?
												<div className="col-md-6">
													<img onClick={() => this.onclickseturl()} style={{"height":"100px","width":"100px","cursor":"pointer"}} className="img-responsive img-circle" src={this.state.detail.photo_path_thumb} />
												</div>:""
											}												
											</div>											
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div id="myModal" className="modal fade imgmodal" role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<button type="button" className="btn btn-default closeicon" data-dismiss="modal">
							<span aria-hidden="true">&times;</span>
						</button>
						<div className="modal-body">
							<img className="showimage img-responsive" src={this.state.detail.photo_path} />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>)
	}
}