import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link, withRouter } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class userPayment extends Component {
	constructor(props) {
    super(props);
    this.state = {
      userData: '',
      loading: true,
      loader: null
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Artist Revenue';
    const userid = this.props.match.params.id;

    this.setState({
      userid: userid,
    });

    this.getuserdata();
    this.getallreportlist();
  }
  getuserdata() {
	const obj = { user_id: this.props.match.params.id };
	const axiosConfig = {
	  'Content-Type': 'multipart/form-data',
	  "Access-Control-Allow-Origin": "*",
	};
  
	Axios.post(API_URL + 'user-detail.php', obj, { axiosConfig })
	  .then((res) => {
		if (res.data.res_status === "200") {
		  this.setState(
			{
			  userData: res.data.res_data,
			  loader: false,
			},
			// Initialize DataTable only after userData is loaded
			this.getallreportlist // Callback to initialize DataTable
		  );
		}
	  });
  }
  
  getallreportlist = () => {
	const { userData } = this.state;
  
	if (!userData) return; // Ensure userData is loaded
  
	this.setState({ loading: true });
	let obj = { user_id: this.props.match.params.id };
  
	setTimeout(() => {
	  let today = new Date();
	  let time = `${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
	  
	  // Constructing file name after ensuring userData is available
	  let fileName = `${userData.first_name || 'User'}-${userData.last_name || 'Name'}_revenue_${time}`;
  
	  $('#myevents').DataTable({
		"ajax": { type: 'POST', url: API_URL + 'artistrevenue.php', data: obj },
		"destroy": true,
		"processing": false,
		"pageLength": 10,
		"stateSave": true,
		"language": {
		  "search": "Search",
		},
		"dom": 'Bfrtip',
		"buttons": [
		  {
			"extend": 'csv',
			"title": fileName,
			"exportOptions": {
			  "columns": [0, 1, 2, 3],
			},
		  },
		  {
			"extend": 'print',
			"title": fileName,
			"exportOptions": {
			  "columns": [0, 1, 2, 3],
			},
		  },
		],
		"columnDefs": [
		  { "type": "num-fmt", "targets": 2, "className": "text-center", "appliesTo": "print" },
		  { "type": "num-fmt", "targets": 3, "className": "text-center", "appliesTo": "print" },
		],
		"bSort": false,
	  });
	}, 500);
	
	this.setState({ loading: false });
  };
  
    
    render() {
		const {userData} = this.state;
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title" style={{"text-transform": "capitalize"}}>{userData.first_name + ' ' + userData.last_name}</h3>
								  <div className="box-tools">
									  <Link to='#' className="btn btn-primary" onClick={this.props.history.goBack}><i className="fa fa-angle-double-left"></i>&nbsp;Back</Link>
								  </div>
								</div>
								<div className="box-body no-padding">
								  <div className="table-responsive">
									<table id="myevents" className="table no-margin table-striped table-hover">
									<thead>
									  <tr>
									  	<th>Description</th>
										<th>Date</th>										
										<th className="text-center">Wallet Credit ($)</th>
										<th className="text-center">Wallet Debit ($)</th>
									  </tr>
									</thead>
									
								</table>
							  </div>              
							</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}