import React, { Component } from 'react';
import Axios from 'axios';
import ls from 'local-storage';
import { Link,NavLink } from 'react-router-dom';
import { API_URL, GOOGLE_API_KEY } from '../config/constants.js';
import defaultimg from '../components/default.png';
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Chart from "react-apexcharts"; 
import $ from 'jquery';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import GoogleMapReact from 'google-map-react'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Geocode from "react-geocode";
import Spinner from '../includes/Spinner.component';
const base_api_url = API_URL;


const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
};
const mapStyles = {
  width: '98.5%',
  height: '97.5%'
};
export class ArtistDashboard extends Component {

	constructor(props) {
		super(props);
		
		var today = new Date();
		var date = '';
		var year  = today.getFullYear();
		var month = today.getMonth() + 1;
		var todaydate = today.getDate();
		if(month<10){
			var month = '0'+month;
		}
		
		if(todaydate<10){
			var todaydate = '0'+todaydate;
		}
		
		date = year + '-' + month + '-' + todaydate;

		this.onChangeStartDate = this.onChangeStartDate.bind(this);
		this.onChangeEndsDate = this.onChangeEndsDate.bind(this);

		this.state = {
			songstats:'0',
			eventstats: '0',
			listener: '0',
			registered:'0',
			totallistner:'0',
			revenue: '0',
			ticket_revenue:'0',
			song_revenue: '0',
			totalsong: '0',
			approvesong: '0',
			completedevents: '0',
			upcomingevents: '0',
			chartData:[],
			song:[],
			events: [],
			country_stat:[],
			token: ls.get('token'),
			error: null,
			msg: null,
			successclass: null,
			loading: true,
			membership_status: ls.get('membership_status'),
			redirect: false,
			activebox: 'song',
			boxactone: 'info-box active',
			boxacttwo: 'info-box',
			boxactthr: 'info-box',
			boxactFou: 'info-box',
			startdate: new Date(ls.get('membership_date')),
			enddate: new Date(date),
			requirederror: false,
			linecharttitle: 'Songs',
			errormsg: '',
			monthbtnclass:'btn btn-primary btn-sm',
			yearbtnclass:'btn btn-secondary btn-sm',
			allbtnclass:'btn btn-secondary btn-sm',
			custombtnclass:'btn btn-secondary btn-sm',
			isActive: false,
			currentdate: date,
			markers: [],
			listenerAddress:[],
			selected:'monthly',
			options: {
			  chart: {
				height: 350,
				type: 'bar',
				zoom: {
				  enabled: false
				}
			  },
			  //colors: ["#b8a25e","#9e6a00"],
			  dataLabels: {
				enabled: true,
				style: {
				  //colors: ['#b8a25e','#9e6a00']
				}
			  },
			  stroke: {
				curve: 'smooth'
			  },          
			  xaxis: {
				categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			  },
			  yaxis: [
			  {
			  	labels: {
			      formatter: (value) => { return value },
			    }
			  }
			]
			},
			series: [
				{
					name: "Song Played",
					data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				},
				{
					name: "Approved Songs",
					data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				}
			  ],
			seriesDonut: [0],
            optionsDonut: {
              chart: {
                type: 'donut',
                width: 90
              },
              labels: [''],
			  legend: {
			    show: false
			  },
			  dataLabels: {
				  enabled: true,
				  enabledOnSeries: undefined,
				  formatter: function (val, opts) {
				      return Math.round(val)+'%'
				  },
				  textAnchor: 'middle',
				  distributed: false,
				  offsetX: 0,
				  offsetY: 0,
				  style: {
				      fontSize: '14px',
				      colors: undefined
				  },
				  background: {
				    enabled: true,
				    foreColor: '#000',
				    borderColor: undefined,
				    dropShadow: {
				      enabled: false,
				    }
				  },
				  dropShadow: {
				      enabled: false,
				  }
				},
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
		}
	}

	

	componentDidMount() {
		document.title = "CONQER Music - My Dashboard";
		this.getStatsData('monthly');
		this.songStatsChart('monthly');
		Geocode.setApiKey("AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0");
		Geocode.setLanguage("en");
		const script = document.createElement('script')
	    script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
	    script.async = true
	    document.body.appendChild(script)
	}
	

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  }
	onclickseturl = () => {
		$('#myModal').modal('show');
	}
	addDefaultSrc = (ev) => {
		ev.target.src = defaultimg
	}
	setFilter = (selectedvalue) =>{
		var select = selectedvalue;
		if(this.state.activebox=='song'){
			this.songStatsChart(select);
			this.getStatsData(select);
		}else if(this.state.activebox=='event'){
			this.eventsStatsChart(select);
			this.getStatsData(select);
		}else if(this.state.activebox=='listener'){
			this.listenerStatsChart(select);
			this.getStatsData(select);
		}else if(this.state.activebox=='revenue'){
			this.revenueStatsChart(select);
			this.getStatsData(select);
		}else{
			this.songStatsChart(select);
			this.getStatsData(select);
		}
		
		if(selectedvalue == 'all'){
			this.setState({
				monthbtnclass:'btn btn-secondary btn-sm',
				yearbtnclass:'btn btn-secondary btn-sm',
				allbtnclass:'btn btn-primary btn-sm',
				custombtnclass:'btn btn-secondary btn-sm',
				isActive: false,
				selected:'all',
			});
		}

		if(selectedvalue == 'monthly'){
			this.setState({
				monthbtnclass:'btn btn-primary btn-sm',
				yearbtnclass:'btn btn-secondary btn-sm',
				allbtnclass:'btn btn-secondary btn-sm',
				custombtnclass:'btn btn-secondary btn-sm',
				isActive: false,
				selected:'monthly',
			});
		}

		if(selectedvalue == 'yearly'){
			this.setState({
				monthbtnclass:'btn btn-secondary btn-sm',
				yearbtnclass:'btn btn-primary btn-sm',
				allbtnclass:'btn btn-secondary btn-sm',
				custombtnclass:'btn btn-secondary btn-sm',
				isActive: false,
				selected:'yearly',
			});
		}		
	}
	songStatsChart = (selected) => {
		this.setState({
			//loading:true
			preloader:true
		});
		const obj = {
			artistid: ls.get('userid'),
			data_for:'songs',
			type: selected,
			from: this.state.startdate,
			to: this.state.enddate
		}
		Axios.post(base_api_url + 'artistsongchartdata.php', obj, { axiosConfig })
			.then(
				(res) => {					
					if (res.data.res_status == 200) {
						const response = res.data.res_data.res_chart_data;
						this.setState({
							series: [
								{
								  name: 'Song Played',
								  data: response[0]
								},
								{
									name: 'Approved Songs',
									data: response[1]
								}
							  ],
							  //loading:false
							  preloader:false
						})
					}else{
						
					}
			}
		);	
	}

	eventsStatsChart = (selected) => {
		const obj = {
			artistid: ls.get('userid'),
			type: selected,
			from: this.state.startdate,
			to: this.state.enddate
		}
		this.setState({
			//loading:true
			preloader:true
		});
		Axios.post(base_api_url + 'artisteventchartdata.php', obj, { axiosConfig })
			.then(
				(res) => {	
								
					if (res.data.res_status == 200) {
						const response = res.data.res_chart_data;
						this.setState({
							series: [
								{
								  name: 'Upcoming Events',
								  data: response[0]
								},
								{
								  name: 'Past Events',
								  data: response[1]
								}
							  ],
							  //loading:false
							  preloader:false
						})
					}else{
						
					}
			}
		);	
	}

	listenerStatsChart = (selected) => {
		const obj = {
			artistid: ls.get('userid'),
			type: selected,
			from: this.state.startdate,
			to: this.state.enddate
		}
		this.setState({
			//loading:true
			preloader:true
		});
		Axios.post(base_api_url + 'artistlistenerchartdata.php', obj, { axiosConfig })
			.then(
				(res) => {					
					if (res.data.res_status == 200) {
						const response = res.data.res_chart_data;
						this.setState({
							series: [
								{
								  name: 'Listeners',
								  data: response[0]
								},
								{
								  name: 'Followers',
								  data: response[1]
								}
							  ],
							  //loading:false
							  preloader:false
						})
					}else{
						
					}
			}
		);	
	}
	revenueStatsChart = (selected) => {
		const obj = {
			artistid: ls.get('userid'),
			type: selected,
			from: this.state.startdate,
			to: this.state.enddate
		}
		this.setState({
			//loading:true
			preloader:true
		});
		Axios.post(base_api_url + 'artistrevenuechartdata.php', obj, { axiosConfig })
			.then(
				(res) => {					
					if (res.data.res_status == 200) {
						const response = res.data.res_chart_data;
						this.setState({
							series: [
								{
								  name: 'Music Revenue',
								  data: response[0]
								},
								{
								  name: 'Tickets Revenue',
								  data: response[1]
								}
							  ],
							  //loading:false
							  preloader:false
						})
					}else{
						
					}
			}
		);	
	}

	randomDataBoxOne = (e) => {
		e.preventDefault();
		this.songStatsChart(this.state.selected);
		this.setState({
			boxactone: 'info-box active',
			boxacttwo: 'info-box',
			boxactthr: 'info-box',
			boxactFou: 'info-box',
			activebox: 'song',
			linecharttitle: 'Songs',
		});
	}

	randomDataBoxTwo = (e) => {
		this.eventsStatsChart(this.state.selected);
		this.setState({
			boxactone: 'info-box',
			boxacttwo: 'info-box active',
			boxactthr: 'info-box',
			boxactFou: 'info-box',
			activebox: 'event',
			linecharttitle: 'Events',
		});
	}

	randomDataBoxThree = (e) => {
		this.listenerStatsChart(this.state.selected);

		this.setState({
			boxactone: 'info-box',
			boxacttwo: 'info-box',
			boxactthr: 'info-box active',
			boxactFou: 'info-box',
			activebox: 'listener',
			linecharttitle: 'Users',
		});
	}

	randomDataBoxFour = (e) => {
		this.revenueStatsChart(this.state.selected);

		this.setState({
			boxactone: 'info-box',
			boxacttwo: 'info-box',
			boxactthr: 'info-box',
			boxactFou: 'info-box active',
			activebox: 'revenue',
			linecharttitle: 'Revenue',
		});
	}

	myFunction = () => {
		this.setState({
			isActive: !this.state.isActive,
			selected:'custom',
			monthbtnclass:'btn btn-secondary btn-sm',
			yearbtnclass:'btn btn-secondary btn-sm',
			allbtnclass:'btn btn-secondary btn-sm',
			custombtnclass:'btn btn-primary btn-sm'
		})
	}

	onChangeStartDate(stdate){
		this.setState({
		  startdate: stdate
		});
	}
	
	onChangeEndsDate(endate){
		this.setState({
			enddate: endate
		})
	}
	
	getStatsData = (selected) => {
		const startdatedata = moment(this.state.startdate);
		const enddatedata = moment(this.state.enddate);
		const obj = {
			artistid: ls.get('userid'),
			type: selected,
			from: startdatedata.format('yyyy-MM-DD'),
			to: enddatedata.format('yyyy-MM-DD')
		}
		this.setState({
			loading: true
		});
		Axios.post(base_api_url + 'artist-dashboard.php', obj, { axiosConfig })
			.then(
				(res) => {
					this.setState({
						loading: false
					});
					if (res.data.res_status == 200) {
						//this.getLatLng();
										
						this.setState({
							eventstats: res.data.res_data.total_event,
							completedevents: res.data.res_data.total_past_event,
							upcomingevents: res.data.res_data.total_upcoming_events,
							events: res.data.res_data.event_list,
							songstats: res.data.res_data.songstats,
							totalsong: res.data.res_data.totalsong,
							approvesong: res.data.res_data.approvesong,
							listener: res.data.res_data.total_listener,
							totallistner:res.data.res_data.total_listener_follower,
							registered: res.data.res_data.total_registered_listener,
							ticket_revenue: res.data.res_data.ticket_revenue,
							song_revenue: res.data.res_data.song_revenue,
							revenue: res.data.res_data.total_revenue,
							song: res.data.res_data.song_data,
							loading: false,
							country_stat: res.data.res_data.country_stat,
							seriesDonut: res.data.res_data.demography_state_data,
							markers: res.data.res_data.list_address,
							markersCenter: res.data.res_data.list_address[0],
							optionsDonut: {
				              labels: res.data.res_data.demography_state_label,
				              legend: {
							    show: false
							  },
							  dataLabels: {
							  enabled: true,
							  enabledOnSeries: undefined,
							  formatter: function (val, opts) {
							      return Math.round(val)+'%'
							  },
							  textAnchor: 'middle',
							  distributed: false,
							  offsetX: 0,
							  offsetY: 0,
							  style: {
							      fontSize: '14px',
							      colors: undefined
							  },
							  background: {
							    enabled: true,
							    foreColor: '#000',
							    borderColor: undefined,
							    dropShadow: {
							      enabled: false,
							    }
							  },
							  dropShadow: {
							      enabled: false,
							  }
							},
				          	}
						});
					}
				}
			);
	}
	getLatLng = (add) =>{
		let MarkerArray = [];
		if(add.length){
		add.map((address, i) =>{
	        Geocode.fromAddress(address.listener_address).then(
			  (response) => {
			    const { lat, lng } = response.results[0].geometry.location;
			    MarkerArray.push({
			        lat, lng
			    });
			    this.setState({
			    	markers: MarkerArray
			    });
			  },
			  (error) => {
			    console.error(error);
			  }
			);	
	    });
		}else{
			this.setState({
			  	markers: MarkerArray
			});
		}
	}
	setGoogleMapRef (map, maps) {
	    this.googleMapRef = map
	    this.googleRef = maps
	    let locations = this.state.markers;
	    let markers = locations && locations.map((location) => {
	      return new this.googleRef.Marker({position: location})
	    })
	    const markerCluster = new MarkerClusterer({ map, markers });
	}
	render() {
		if(this.state.loading==true){
			return <Spinner />;
		}
		const shiftSize = 10;
		const defaultLabelStyle = {
			
			fontSize: '5px',
			fontFamily: 'sans-serif',
		  };
		const isActive = this.state.isActive;
		let songdata = this.state.song;
		let maxlimit = '15';
		return (
			<React.Fragment>
				<div className="row smartbuttons">
					<div className="col-md-12">
						<div className="multifilter">
							<button type="button" className={this.state.allbtnclass} onClick={() => this.setFilter('all')}>ALL</button>
							<button type="button" className={this.state.yearbtnclass} onClick={() => this.setFilter('yearly')}>YTD</button>
							<button type="button" className={this.state.monthbtnclass} onClick={() => this.setFilter('monthly')}>MTD</button>
							<button className={this.state.custombtnclass + ' popup'} onClick={this.myFunction}>Custom Date</button>
							<span className={isActive ? "popuptext show" : "popuptext"} id="myPopup">
								<table width="100%">
									<tr>
										<td className="fisrtow">
											<DatePicker
											  selected={this.state.startdate}
											  onChange={ this.onChangeStartDate }
											  name="startdate"
											  dateFormat="MM/dd/yyyy"
											  dropdownMode="select"
											  showMonthDropdown
											  showYearDropdown
											  adjustDateOnChange
											  required={true}
											  maxDate={new Date(this.state.currentdate)}
											  placeholderText = "MM/DD/YYYY"
											  className="form-control"
											  id="startdate"
											/>
										</td>
										<td className="fisrtow">
											<DatePicker
											  selected={this.state.enddate}
											  onChange={ this.onChangeEndsDate }
											  dateFormat="MM/dd/yyyy"
											  dropdownMode="select"
											  showMonthDropdown
											  showYearDropdown
											  adjustDateOnChange
											  required={true}
											  minDate={new Date(this.state.startdate)}
											  maxDate={new Date(this.state.currentdate)}
											  placeholderText = "MM/DD/YYYY"
											  className="form-control"
											/>
										</td>
										<td><input type="button" name="button" id="button" onClick={() => this.setFilter('custom')} value="GO"></input></td>
									</tr>
								</table>
							</span>
							
						</div>						
					</div>
				</div>
				
				{(this.state.membership_status != 'Active')?
				<div className="col-md-12 membershipmsg">				
					<p>{(this.state.membership_status == 'Inactive') ? 'Your account subscription has ended, Please renew your subscription plan.':(this.state.membership_status == null)?"You don't have any membership, Please purchase a subscription plan.":''}<br /><NavLink to={'/renew-plan/'+this.state.token} >Click Here </NavLink> </p>					
				</div>
				:<div></div>}
				<div className="row kpisarea">
					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className={this.state.boxactone} onClick={this.randomDataBoxOne}>
						<span  title="Song Played">
							<div className="infoheaderarea">
								<span className="info-box-icon bg-songs"><i className="fa fa-music"></i></span>
								<span className="info-box-text">Songs</span>								
							</div>

							<div className="info-box-content">
								<span className="info-box-number">{this.state.songstats}</span>
							</div>
							</span>
							<div className="infor-box-footerarea">
								<span className="footerprogress" title="Total Uploads"><i className="fa fa-upload"></i> {this.state.totalsong}</span>
								<span className="footerprogress right" title="Total Approved"><i className="fa fa-check-circle"></i> {this.state.approvesong}</span>
							</div>

						</div>

					</div>

					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className={this.state.boxacttwo} onClick={this.randomDataBoxTwo}>
						<span title="Events">
							<div className="infoheaderarea">
								<span className="info-box-icon bg-events"><i className="fa fa-calendar"></i></span>
								<span className="info-box-text">Events</span>								
							</div>

							<div className="info-box-content">
								<span className="info-box-number">{this.state.eventstats}</span>
							</div>
							</span>
							<div className="infor-box-footerarea">
								<span className="footerprogress" title="Upcoming Events"><i className="fa fa-calendar-minus-o"></i> {this.state.upcomingevents}</span>
								<span className="footerprogress right" title="Past Events"><i className="fa fa-calendar-check-o"></i> {this.state.completedevents}</span>
							</div>
						</div>
					</div>

					<div className="clearfix visible-sm-block"></div>

					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className={this.state.boxactthr} onClick={this.randomDataBoxThree}>
						<span title="Users">
							<div className="infoheaderarea">
								<span className="info-box-icon bg-listener"><i className="fa fa-users"></i></span>
								<span className="info-box-text">Users</span>
							</div>

							<div className="info-box-content">
								<span className="info-box-number">{this.state.totallistner}</span>
							</div>
							</span>
							<div className="infor-box-footerarea">
								<span className="footerprogress" title="Listeners"><i className="fa fa-headphones"></i> {this.state.listener}</span>
								<span className="footerprogress right" title="Followers"><i className="fa fa-user"></i> {this.state.registered}</span>
							</div>

						</div>

					</div>

					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className={this.state.boxactFou} onClick={this.randomDataBoxFour}>
						<span  title="Revenue">
							<div className="infoheaderarea">
								<span className="info-box-icon bg-rev"><i className="fa fa-usd"></i></span>
								<span className="info-box-text">Revenue</span>								
							</div>

							<div className="info-box-content">
								<span className="info-box-number">${this.state.revenue}</span>
							</div>
							</span>
							<div className="infor-box-footerarea">
								<span className="footerprogress" title="Music Revenue"><i className="fa fa-music"></i> ${this.state.song_revenue}</span>
								<span className="footerprogress right" title="Tickets Revenue"><i className="fa fa-ticket"></i> ${this.state.ticket_revenue}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="row songschart">
					<div className="col-md-9">
						<div className="box box-success">
							<div className="box-header with-border">
								<h3 className="box-title">{this.state.linecharttitle}</h3>
							</div>
							<div className="box-body no-padding">
								<div className="row">
									<div className="col-md-12 line-label">
									{(this.state.preloader)?<div style={{fontSize:'24px', textAlign:'center', display: 'flex',minHeight: '225px',alignItems: 'center',justifyContent: 'center'}}><i class="fa fa-spinner fa-spin" style={{fontSize:'24px'}}></i> Please Wait...</div>:
										<Chart
										  options={this.state.options}
										  series={this.state.series}
										  type="bar"
										  height="210"
										/>
									}
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="box box-success">
							<div className="box-header with-border">
								<h3 className="box-title">Listener Stats <span style={{fontSize:'12px',marginLeft:'40px'}}><Link to="#" onClick={() => this.onclickseturl()}>View Map</Link></span></h3>
							</div>
							<div className="box-body" style={{height:'255px'}}>
								<div className="row">
									<div className="col-md-12">
										<div>
											<Chart options={this.state.optionsDonut} series={this.state.seriesDonut} type="donut" 
										  height="223"/>
										
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					{(songdata.length > 0 )?
					<div className="row topfivesongsd my-3">
						<div className="col-md-12">
							<div className="todpfive">My Most Liked Songs</div>
						</div>
					</div>
					:''}
					{(songdata.length > 0 )?
					<div className="row artisitblock mb-3">
					{this.state.song.map(items => {
						let stitile = items.song_title;
					return (
						<div className="col-md-3">
							<div className="featureartprifles">
							   <div className="featrueimages">
								  <img onError={this.addDefaultSrc} src={items.song_image} />
							   </div>
							   <div className="featurestext">
								  <span className="artname" title={stitile}>{ (stitile)?(stitile.length > maxlimit) ? (((stitile).substring(0,maxlimit-3)) + '...') :stitile:'' }</span>
									<span className="folwershave songlikes"><i className="fa fa-heart"></i> {items.song_like}</span>
									<span className="folwershave playicons"><i className="fa fa-play-circle"></i> {items.song_played}</span>
							   </div>
							</div>
						</div>
						)
					}
				)}
					
				</div>
				:''}

				<div className="row">
					<div className="col-md-12">
						<div className="box box-info">
							<div className="box-header with-border">
								<h3 className="box-title">Latest Events</h3>
							</div>
							<div className="box-body">
								<div className="table-responsive">
									<table className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Event Name</th>
												<th>Start Date</th>
												<th>End Date</th>
												<th style={{textAlign:'center'}}>Tickets</th>
												<th style={{textAlign:'center'}}>Sold</th>
												<th style={{textAlign:'center'}}>Available</th>
												<th style={{textAlign:'center'}}>Revenue</th>
											</tr>
										</thead>
										<tbody>
											{(this.state.events.length)?this.state.events.map((items,index) => {
												if(index < 5){
												return (
													<tr key={index}>
														<td><Link to={'/view-event/'+items.id}>{items.name}</Link></td>
														<td>{items.event_start_date}</td>
														<td>{items.event_end_date}</td>
														<td style={{textAlign:'center'}}>{items.total_tickets}</td>
														<td style={{textAlign:'center'}}>{items.sold}</td>
														<td style={{textAlign:'center'}}><span className="label label-success">{items.available}</span></td>
														<td style={{textAlign:'center'}}>{items.revenue}</td>
													</tr>
												)
											}
											}):<tr><td colspan="7" style={{textAlign:'center'}}>No Data Found!</td></tr>}

										</tbody>
									</table>
								</div>
							</div>
							<div className="box-footer clearfix">
								<Link to={'/my-events/'+this.state.token } className="btn btn-sm btn-info btn-flat pull-right">View All</Link>

							</div>

						</div>
					</div>
				</div>
				<div id="myModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog modal-lg" style={{maxWidth:'1080px'}}>
						<div className="modal-content">
							<button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
							<div className="modal-body" style={{height:'500px'}}>
							<GoogleMapReact
						        bootstrapURLKeys={{key: GOOGLE_API_KEY}}
						        yesIWantToUseGoogleMapApiInternals
						        onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps)}
						        defaultCenter={{lat:37.090240, lng:-95.712891}}
						        defaultZoom={1}
						        maxZoom={10}
						        options={{streetViewControl: true}}
						      ></GoogleMapReact>
							</div>            
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
	
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeY8IgBtkp17ZjUajVLWDP_Lh1gUN0d-0'
})(ArtistDashboard);
