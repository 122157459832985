import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import 'jquery/dist/jquery.min.js';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class Genre extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	subgenrelist:[],
      	genrelist:[],
      	errors:[],
		btndisable:'',
		genre: '',
		subgenre: '',
		genreid:'',
		oldgenre:'',
      	btnSubmitText: 'Submit',
      	disabled:false,
		isSubmitting: false // Added 
      }
    }
    subgenrelistData(){
		const obj= {subgenreid:'0'}
	    Axios.post(API_URL + 'mastersubgenrelist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		subgenrelist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						subgenrelist: [],
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#genrelist').DataTable().destroy();
			        setTimeout(function(){
			        $('#genrelist').DataTable({
						"destroy": true,
						"aaSorting": [],
						"stateSave": true,
						"lengthMenu": [[10, 15, 20, -1], [10, 15, 20,  "All"]],
						"language": {
							"search": "Search"
						},
						dom: 'Bfrtip',
						buttons: [
							{
								extend: 'csv',
								exportOptions: {
									columns: [ 0,1,2 ]
								}
							},
							{
								extend: 'print',
								exportOptions: {
									columns: [ 0,1,2 ]
								},
								customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','15%');
								}
							}
						]
			        });
			        } ,50);
			    });	        	   
	    	}
	    );
    }
	genrelistData(){
		const obj= {genreid:'0'}
	    Axios.post(API_URL + 'mastergenrelist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		genrelist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						genrelist: [],
						loader: false
					});
		        }	        	   
	    	}
	    );
    }
    componentDidMount(){
		document.title = "Master - Sub Genre";
    	this.subgenrelistData();
    	this.genrelistData();
    	$(document).ready(function () {
    		$('#genrelist').DataTable().destroy();
	        setTimeout(function(){
	        $('#genrelist').DataTable({
					"destroy": true,
					"aaSorting": [],
					"stateSave": true,
					"lengthMenu": [[ 10, 15, 20, -1], [10, 15, 20,  "All"]],
					"language": {
						"search": "Search"
					},
					dom: 'Bfrtip',
					buttons: [
						{
							extend: 'csv',
							exportOptions: {
								columns: [ 0,1,2 ]
							}
						},
						{
							extend: 'print',
							exportOptions: {
								columns: [ 0,1,2 ]
							},
							customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
							}
						}
					]
	        });
	        } ,50);
	    }); 
    }
	
    editGenre = (subgenre_id) => {
		if(this.state.errors['genreerror']){delete this.state.errors['genreerror'];}
		if(this.state.errors['subgenreerror']){delete this.state.errors['subgenreerror'];}
    	this.setState({
			loader:true,
			subgenreid:subgenre_id,
			genreerror:''
		})
		const obj = {
			subgenreid: subgenre_id
		}
		Axios.post(API_URL + 'mastersubgenrelist.php', obj, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					let currentc = res.data.res_data[0];
					this.setState({
						genre: currentc.parent_id,
						oldgenre: currentc.parent_id,
						subgenre: currentc.name,
						oldsubgenre: currentc.name,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
				this.subgenrelistData();
			}
		);
	}
	deleteSubGenre(id){
    	if(window.confirm("Are you sure you want to delete the sub genre?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deletesubgenre.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					this.subgenrelistData();
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
	
	onBlursubgenre(e) {
		if (this.state.isSubmitting) return; // added
		if(this.state.errors['subgenreerror']){delete this.state.errors['subgenreerror'];}
		let subgenreVal = e.target.value;
		if(subgenreVal !== this.state.oldsubgenre){
			const obj = {
				subgenre:subgenreVal,
				genre:this.state.genre
			}			
			Axios.post(API_URL+'mastersubgenre_exist.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						errors["subgenreerror"] = res.data.res_message;
						//this.setState({errors: errors,subgenre: '',genre:''});
						this.setState({errors: errors});
					}else{
						errors["subgenreerror"] = res.data.res_message;
						this.setState({errors: errors,subgenre: subgenreVal,genre:this.state.genre});
					}
				}
			);
		}
	}

	onChangegenre(e) {
		if(this.state.errors['genreerror']){delete this.state.errors['genreerror'];}
		this.setState({
			genre: e.target.value
		})
	}
	onChangesubgenre(e) {
		if(this.state.errors['subgenreerror']){delete this.state.errors['subgenreerror'];}
		this.setState({
			subgenre: e.target.value
		})
	}
	/*
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	} */
		onCommissionSubmit(e) {
			e.preventDefault();
			this.setState({ isSubmitting: true }, () => {
				if (this.handleValidation()) {
					this.setState({
						loader: true,
						disabled: true,
						btnSubmitText: 'Please Wait...',
					});
					if (this.state.method === 'update') {
						this.updateTickets();
					} else {
						this.saveTickets();
					}
				} else {
					this.setState({ isSubmitting: false });
				}
			});
		}
	saveTickets(){
		const formData = new FormData();
		formData.append("genre", this.state.genre);
		formData.append("subgenre", this.state.subgenre);
		Axios.post(API_URL + 'save_subgenre.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						genre: '',
						subgenre: '',
						btnSubmitText: 'Submit',
						disabled:false,
						loader:false,
						isSubmitting: false,
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						isSubmitting: false,
						msg: res.data.res_message								
					})
				}
				this.subgenrelistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("subgenre_id", this.state.subgenreid);
		formData.append("genre", this.state.genre);
		formData.append("subgenre", this.state.subgenre);
		Axios.post(API_URL + 'update-subgenre.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						genre: '',
						subgenre: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						genre: this.state.genre,
						subgenre: this.state.subgenre,
						msg: res.data.res_message,
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				this.subgenrelistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;	
		if(!this.state.genre){
			formIsValid = false;
			errors["genreerror"] = "Please select genre.";
		}
		if(!this.state.subgenre){
			formIsValid = false;
			errors["subgenreerror"] = "Please enter sub genre.";
		}
		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updatesubgenrestatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message
					})
				}
				this.subgenrelistData();
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Sub Genre</h3>
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Sub Genre Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<div className="form-group">
															<label>Genre<span className="requiredastrik">*</span></label>
															<select className="custom-select" name="type" value={this.state.genre} onChange={this.onChangegenre.bind(this)}>
																<option value="">Select Genre</option>
																{this.state.genrelist.map((result,index) => {
																	if(result.status == '1'){
																		return (
																			<option value={result.id} key={index} >{result.name}</option>
																		)
																	}
																	})
																}
															</select>
															<font className="errorMsg">{this.state.errors["genreerror"]}</font>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-group">
															<label>Sub Genre<span className="requiredastrik">*</span></label>
															<input type="text" className="form-control" name="subgenre" value={this.state.subgenre} onChange={this.onChangesubgenre.bind(this)} /*onBlur={this.onBlursubgenre.bind(this)} */ />
															<font className="errorMsg">{this.state.errors["subgenreerror"]}</font>
														</div>
													</div>
													<div className="col-md-4 mt-4">
														<button type="submit" style={{'height':'34px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="genrelist" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Genre</th>
												<th>Sub Genre</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.subgenrelist.map((result,index) => {
												return (
													<tr key={index}>
														<td>{result.parent_name}</td>
														<td>{result.name}</td>
														<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{(result.status==='1')?"Active":"In Active"}</span></td>
														<td className="actionbtns d-flex justify-content-center" style={{textAlign:'center'}}><Link to="#" title="Edit Sub Genre" onClick={() => this.editGenre(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
														<Link to="#" title="Delete Sub Genre" onClick={() => this.deleteSubGenre(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}