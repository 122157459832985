import React from "react";
import { withRouter, Redirect, Link } from 'react-router-dom';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
	'Content-Type': 'multipart/form-data',
	"Access-Control-Allow-Origin": "*",
}

class RenewForm extends React.Component {

	constructor(props) {
		super(props);
		this.onChangeSubscription = this.onChangeSubscription.bind(this);
		this.state = {
            email: ls.get('email'),
            userid :  ls.get('userid'),
			planid: '',
			error: null,
			msg: null,
			emailmsg: null,
			loader: null,
			payment: false,
			planlist:[],
			usertype: null,
			successclass: 'hideclass',
			redirect: false,
			submitbuttontext: 'Submit'
		}

		
	}
	componentDidMount(){
		let currentLocation = window.location.pathname;	
		const userrole = ls.get('role');
		if(userrole=='2'){	
			var utype = 'artist';
		}else{
			var utype = 'listener';
		}
		this.getplandetails(utype);

	}
	getplandetails = (utype) => {
		
		const usertype = utype;
		const obj = {
			usertype: usertype
		}

		Axios.post(base_api_url + 'getplandetails.php', obj)
			.then(
				(res) => {
					if (res.data.res_status == 200) {
						this.setState({
							planlist: res.data.res_data,
							usertype: usertype
						});
					} else {
						this.setState({
							planlist: [],
							usertype: usertype
						});
					}
				}
			);
	}

	onChangeSubscription(e) {
		this.setState({
			planid: e.target.value
		})
	}

	handleSubmit = async event => {
		event.preventDefault();

		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			return;
		}
		this.setState({
			submitbuttontext:'Processing...'
		});
		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);
		if (result.error) {
			
			this.setState({
				successclass: 'errormsg',
				msg: 'Pyament process is fail, please try again!'
			});
			
			console.log(result.error.message);
			
		} else {
			this.stripeTokenHandler(result.token,this.state.email,this.state.planid);
			console.log('result-token',result.token);
		}
	};

	stripeTokenHandler = (token,email,planid) => {
		this.setState({
			loader:true
		});
		const paymentData = { token: token.id, email: email, membership: planid};
		Axios.post(base_api_url + 'stripepay.php', paymentData, { axiosConfig })
			.then(
				(res) => {console.log(res.data);
					if (res.data.res_status == 200) {
						const formData = new FormData();
						formData.append("planid", this.state.planid);
						formData.append("userid", this.state.userid);
						formData.append("email", email);
						Axios.post(base_api_url + 'renew-plan.php', formData, { axiosConfig })
							.then(
								(res) => {
                                    if (res.data.res_status == 200) {
										ls.set('membership_status', 'Active');
										this.setState({
											loader:null,
											redirect: false,
											successclass: 'successmsg',
											submitbuttontext : 'Submit',
											msg: 'You have successfully subscribed for CONQER membership!'
										});
										//console.log(res.data);
									} else {
										window.scrollTo(0, 0);
										this.setState({
											loader:null,
											redirect: false,
											successclass: 'errormsg',
											submitbuttontext : 'Submit',
											msg: 'Subscription is failed, please try again!'
										})
									}
									setTimeout(() => {										

										this.setState({
											loader:null,
											submitbuttontext : 'Submit',
											successclass: 'hideclass',
											msg: null,
											redirect: true
										});
									}, 5000);
								}
							);
					
					} else {
						this.setState({
							loader:null,
							submitbuttontext : 'Submit',
							payment: false
						})
					}
				}
			);
	}

	render() {
		if(this.state.redirect==true){
			const userrole = ls.get('role');
			if (userrole == 2) {
				return <Redirect to="/artist-dashboard" />
			} else if (userrole == 1) {
				return <Redirect to="/listener-dashboard" />
			}
		}
		return (
			<React.Fragment>
				<div className={this.state.successclass}>{this.state.msg}</div>
					  <div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  <h3 className="box-title">{(ls.get('membership_status'))?"Renew Membership Plan":"Purchase Membership Plan"}</h3>
								</div>
								<div className="box-body no-padding">
								  <form className="artist-form" onSubmit={this.handleSubmit}>
								  <div className="row">
									<div className="col-md-12">
									  <div className="sectionhead">
										<div className="headsec">
										  <h3>Purchase memberhsip plan</h3>
										</div>
									  </div>
									</div>
								  </div>
								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
										<label htmlFor="albumtitle">Subscription Plan<span className="requiredastrik">*</span></label>
										<select id="subscription" required className="form-control" name="" onChange={this.onChangeSubscription} >
                                            <option value="0">--Select Membership Plan--</option>
                                            {this.state.planlist.map((result) => {
                                            return (<option value={result.id}>{result.plan} ${result.amount}</option>)
                                            })}
                                        </select>
									  </div>
									</div>
									
								  </div>

								  <div className="row">
									<div className="col-md-12">
									  <div className="form-group">
                                       <label>Credit Card<span className="requiredastrik">*</span></label>
											<div className="creditcardsection"><CardElement options={{ hidePostalCode: true }} /></div>
									  </div>
									</div>									
								  </div>								  
								  <div className="row">
								  
									<div className="col-md-12 notmainbtn">
										<button type="submit" className="btn btn-primary">
											<i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
											{this.state.submitbuttontext}</button>										
									</div>
								  </div>
								  </form>
								</div>								
							</div>
						</div>        
					  </div> 
			</React.Fragment>
		);
	}
}

export default function InjectedRenewForm() {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<RenewForm stripe={stripe} elements={elements} />
			)}
		</ElementsConsumer>
	);
}