import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Link } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import 'jquery/dist/jquery.min.js';
import Spinner from '../../../includes/Spinner.component';
import $ from 'jquery'; 
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
const errors = {};
export default class Genre extends Component {
	constructor(props) {
      super(props);
      this.state = {
      	loader:true,
      	genrelist:[],
      	errors:[],
		btndisable:'',
		genre: '',
		genreid:'',
		oldgenre:'',
      	btnSubmitText: 'Submit',
      	disabled:false,
		isSubmitting: false // Added 
      }
    }
    genrelistData(){
		const obj= {genreid:'0'}
	    Axios.post(API_URL + 'mastergenrelist.php', obj,{ axiosConfig })
	    .then(
	      	(res) => {
		        if(res.data.res_status === "200"){		        	
		        	this.setState({
		        		genrelist: res.data.res_data,
						loader: false
					});				         
		        } else{
		        	this.setState({
						genrelist: [],
						loader: false
					});
		        }
		      	$(document).ready(function () {
		    		$('#genrelist').DataTable().destroy();
			        setTimeout(function(){
			        $('#genrelist').DataTable({
						"destroy": true,
						"aaSorting": [],
						"stateSave": true,
						"lengthMenu": [[10, 15, 20, -1], [10, 15, 20,  "All"]],
						"language": {
							"search": "Search"
						},
						dom: 'Bfrtip',
						buttons: [
							{
								extend: 'csv',
								exportOptions: {
									columns: [ 0,1]
								}
							},
							{
								extend: 'print',
								exportOptions: {
									columns: [ 0,1 ]
								},
								customize: function ( win ) {
									$(win.document.body).find('table').addClass('display').css('font-size', '15px');
									$(win.document.body).find('table').css('table-layout','auto');
									$(win.document.body).find('th').css('width','15%');
									$(win.document.body).find('td').css('width','15%');
								}
							}
						]
			        });
			        } ,50);
			    });	        	   
	    	}
	    );
    }
    componentDidMount(){
		document.title = "Master - Genre";
    	this.genrelistData();
    	$(document).ready(function () {
    		$('#genrelist').DataTable().destroy();
	        setTimeout(function(){
	        $('#genrelist').DataTable({
					"destroy": true,
					"aaSorting": [],
					"stateSave": true,
					"lengthMenu": [[ 10, 15, 20, -1], [10, 15, 20,  "All"]],
					"language": {
						"search": "Search"
					},
					dom: 'Bfrtip',
					buttons: [
						{
							extend: 'csv',
							exportOptions: {
								columns: [ 0,1]
							}
						},
						{
							extend: 'print',
							exportOptions: {
								columns: [ 0,1 ]
							},
							customize: function ( win ) {
								$(win.document.body).find('table').addClass('display').css('font-size', '15px');
								$(win.document.body).find('table').css('table-layout','auto');
								$(win.document.body).find('th').css('width','15%');
								$(win.document.body).find('td').css('width','15%');
							}
						}
					]
	        });
	        } ,50);
	    }); 
    }
	
    editGenre = (genre_id) => {
		if(this.state.errors['genreerror']){delete this.state.errors['genreerror'];}
    	this.setState({
			loader:true,
			genreid:genre_id,
			genreerror:''
		})
		const obj = {
			genreid: genre_id
		}
		Axios.post(API_URL + 'mastergenrelist.php', obj, { axiosConfig })
		.then(
			(res) => {
				
				if (res.data.res_status == 200) {
					let currentc = res.data.res_data[0];
					this.setState({
						genre: currentc.name,
						oldgenre: currentc.name,
						btnSubmitText: 'Update',
						loader:false,
						method:'update'
					});

				} else {
					this.setState({
						successclass: 'errormsg',
						msg: 'Internal Server Error, please try again!',
						loader:false
					})
				}
				this.genrelistData();
			}
		);
	}
	deleteISRC(id){
    	if(window.confirm("Are you sure you want to delete the genre?")){
    		this.setState({
	    		loader:true
	    	});
    		const obj = {
				id:id
			}			
			Axios.post(API_URL+'deletegenre.php',obj,{axiosConfig})
			.then(
				(res) => {
					if(res.data.res_status==200){
						this.setState({
							successclass:'successmsg',
							msg:res.data.res_message,
						});
					}else{
						this.setState({
							successclass:'errormsg',						
							msg:res.data.res_message
						})
					}
					this.genrelistData();
					setTimeout(() => {
					  this.setState({
						successclass:null,
						msg:null
					  });				  
					}, 10000)
				}
			);
    	}
    }
	
	onBlurgenre(e) {
		if (this.state.isSubmitting) return; // added
		if(this.state.errors['genreerror']){
			delete this.state.errors['genreerror'];
		}
		let genreVal = e.target.value;
		if(genreVal !== this.state.oldgenre){
			const obj = {
				genre:genreVal
			}			
			Axios.post(API_URL+'mastergenre_exist.php',obj,{axiosConfig})
			.then(
				(res) => {
					
					if(res.data.res_status==200){
						errors["genreerror"] = res.data.res_message;
						//this.setState({errors: errors,genre: ''});
						this.setState({errors: errors});
					}else{
						errors["genreerror"] = res.data.res_message;
						this.setState({errors: errors,genre: genreVal});
					}
				}
			);
		}
	}

	onChangegenre(e) {
		if(this.state.errors['genreerror']){delete this.state.errors['genreerror'];}
		this.setState({
			genre: e.target.value
		})
	}
	/*
	onCommissionSubmit(e) {
		e.preventDefault();
		if(this.handleValidation()){
			this.setState({
				loader:true,
				disabled:true,
				btnSubmitText:'Please Wait...'
			});
			if(this.state.method === 'update'){
				this.updateTickets();
			}else{
				this.saveTickets();
			}
		}
	} */
	onCommissionSubmit(e) {
		e.preventDefault();
		this.setState({ isSubmitting: true }, () => {
			if (this.handleValidation()) {
				this.setState({
					loader: true,
					disabled: true,
					btnSubmitText: 'Please Wait...',
				});
				if (this.state.method === 'update') {
					this.updateTickets();
				} else {
					this.saveTickets();
				}
			} else {
				this.setState({ isSubmitting: false });
			}
		});
	}
	saveTickets(){
		const formData = new FormData();
		formData.append("genre", this.state.genre);
		Axios.post(API_URL + 'save_genre.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						genre: '',
						btnSubmitText: 'Submit',
						disabled:false,
						loader:false,
						isSubmitting: false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						btnSubmitText:'Submit',
						disabled:false,
						loader:false,
						msg: res.data.res_message,
						isSubmitting: false								
					})
				}
				this.genrelistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	updateTickets(){
		const formData = new FormData();
		formData.append("genre_id", this.state.genreid);
		formData.append("genre", this.state.genre);
		Axios.post(API_URL + 'update-genre.php', formData, { axiosConfig })
		.then(
			(res) => {
				if (res.data.res_status == 200) {
					this.setState({
						successclass: 'successmsg',
						msg: res.data.res_message,
						genre: '',
						btnSubmitText: 'Submit',
						disabled:false,
						method:'',
						loader:false
					});
				} else {
					this.setState({
						successclass: 'errormsg',
						genre: '',
						msg: res.data.res_message,
						btnSubmitText:'Update',
						disabled:false,
						loader:false,
						method:'update'
					})
				}
				this.genrelistData();
				setTimeout(() => {
					this.setState({
						successclass: null,
						msg: null
					});
				}, 5000)
			}
		)
	}
	handleValidation = () => {
		let formIsValid = true;		
		if(!this.state.genre){
			formIsValid = false;
			errors["genreerror"] = "Please enter genre.";
		}
		
		this.setState({errors: errors});		
        return formIsValid;
	
	}
	changeStatus(id,status){
    	this.setState({
    		loader:true
    	});
    	const obj = {
			id:id,
			status:status
		}			
		Axios.post(API_URL+'updategenrestatus.php',obj,{axiosConfig})
		.then(
			(res) => {
				if(res.data.res_status==200){
					this.setState({
						successclass:'successmsg',
						msg:res.data.res_message,
					});
				}else{
					this.setState({
						successclass:'errormsg',						
						msg:res.data.res_message
					})
				}
				this.genrelistData();
				setTimeout(() => {					
					this.setState({
					successclass:null,
					msg:null
					});
				}, 10000)
			}
		);
    }
    render() {
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
    			<div className={this.state.successclass}>{this.state.msg}</div>
				{(this.state.loader === true)?<Spinner />:
    			<div className="row">
					<div className="col-md-12">
						<div className="box box-success distributions-profileform">
							<div className="box-header with-border addprofilenew">
								<h3 className="box-title">Genre</h3>
							</div>
							<div className="box-body no-padding"> 
								<div className="nav-tabs-custom">
									<div className="">
										<div className={this.state.tabdetailclass} id="tab_1">
											<form name="ticket-types" ref={(el) => this.myFormRef = el} onSubmit={this.onCommissionSubmit.bind(this)}>
												<div className="row">
													<div className="col-md-12">
														<div className="sectionhead">
															<div className="headsec">
																<h3>Genre Information</h3>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>Genre<span className="requiredastrik">*</span></label>
															<input type="text" placeholder={this.state.genre} className="form-control" name="genre" value={this.state.genre} onChange={this.onChangegenre.bind(this)} /*onBlur={this.onBlurgenre.bind(this)} */ />
															<font className="errorMsg">{this.state.errors["genreerror"]}</font>
														</div>
													</div>
													<div className="col-md-6 mt-4">
														<button type="submit" style={{'height':'34px'}} disabled={this.state.disabled} className="btn btn-primary">{this.state.btnSubmitText} </button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>           
							</div>
							<hr />
							<div className="box-body no-padding">
								<div className="table-responsive">
									<table id="genrelist" className="table no-margin table-striped table-hover">
										<thead>
											<tr>
												<th>Genre</th>
												<th>Status</th>
												<th className="actionbtnsets" style={{textAlign:'center'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.genrelist.map((result,index) => {
												if(result.parent_id == '0'){
													return (
														<tr key={index}>
															<td>{result.name}</td>
															<td><span className={(result.status==='1')?"label label-success":"label label-danger"}>{(result.status==='1')?"Active":"In Active"}</span></td>
															<td className="actionbtns d-flex justify-content-center" style={{textAlign:'center'}}><Link to="#" title="Edit Genre" onClick={() => this.editGenre(result.id)} className="btn btn-info custombtn"><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;<Link to="#"  onClick={() => this.changeStatus(result.id,result.status)} title={(result.status === '1' )?'In Active':'Active'} className="btn btn-info custombtn">{(result.status === '1')?<i className="fa fa-ban" aria-hidden="true"></i>:<i className="fa fa-check" aria-hidden="true"></i>}</Link>&nbsp;
															<Link to="#" title="Delete Genre" onClick={() => this.deleteISRC(result.id)} className="btn btn-info custombtn"><i className="fa fa-trash" aria-hidden="true"></i></Link></td>
														</tr>
													)
												}
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>        
				</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}