import React, { Component } from 'react';
import Axios from 'axios';
import { Form } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { API_URL, STRIPE_KEY } from '../../config/constants';
import DatePicker from 'react-datepicker';
import defaultimg from '../../components/default.png';
import AdminHeader from './header.component';
import AdminTopHeader from './header-top.component';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
import $ from 'jquery';
import Spinner from '../../includes/Spinner.component';
import ls from 'local-storage';
const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*'
};
const stripePromise = loadStripe(STRIPE_KEY);
toast.configure();
const errors = {};
export class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userinfo: '',
      planlist:[],
      btnText:'Submit',
      btnDisable: false ,
      fillStatus: false,
      sub_plan:'',
      featuredData:[],
      errors:[],
      stripe_text:'',
      refund_class: "fa fa-refresh",
      disabled: '',
      disabled1: ''
    };
  }

  componentDidMount() {
    document.title = 'Conqer Music - User details';
    this.getData();
    this.getplandetails('artist');
    this.getUserPlanDetail();
  }
  getData = () => {
    this.setState({ loading: true });
    let obj = {
      user_id: this.props.match.params.id
    };
    Axios.post(base_api_url + 'user-detail.php', obj, { axiosConfig }).then((res) => {
      
      if (res.data.res_status == 200) {
        const returndata = res.data.res_data;
        this.setState({
          userinfo: returndata,
          loading: false
        });
      } else {
        this.setState({
          userinfo: '',
          loading: false
        });
      }
    });
  };
  onclickseturl = (urlval) => {
    this.setState({
      urlvalinitial: urlval
    });
    $('#myModal').modal('show');
  };
  addDefaultSrc = (ev) => {
    ev.target.src = defaultimg;
  };
  upgradeFreeAccount(){
    if (window.confirm('Are you sure you want update as a free account?')) {
      const obj = {
        userid: this.props.match.params.id
      }       
      Axios.post(base_api_url+'make-free.php',obj,{axiosConfig})
      .then(
        (res) => {        
          if(res.data.res_status==200){
            toast.success(res.data.res_message);
            this.getData();
          }else{
            toast.error(res.data.res_message);
          }
        }
      );
    }
  }
  upgradeFeaturedProfile(){
    $('#upGradModal').modal('show');
  }
  onChangePlan(e){
    if (this.state.errors['plan_error']) { delete this.state.errors['plan_error']; }
    this.setState({
      sub_plan: e.target.value
    })
  }
  getplandetails = (utype) => {   
      const obj = {
        type: utype
      }
      Axios.post(base_api_url + 'get-featured-plan.php', obj).then(
          (res) => {
            if (res.data.res_status == 200) {
              this.setState({
                planlist: res.data.res_data,
                stripe_text: res.data.stripe_detail
              });
            } else {
              this.setState({
                planlist: [],
                stripe_text: ''
              });
            }
          }
      );
  }
  getUserPlanDetail = () => {
    const obj = {
      userid: this.props.match.params.id,
      type: "user"
    }       
    Axios.post(base_api_url+'user-featuerd-plan.php',obj,{axiosConfig})
    .then(
      (res) => {        
        if(res.data.res_status==200){
          this.setState({
            featuredData:res.data.res_data
          });
        }
      }
    );
  }
  async onSubmit(e) {
    e.preventDefault();
    if (this.handleValidationUpgrad()) {
      this.setState({
        btnText:'Please Wait..',
        btnDisable:true
      });
      await this.onToken();      
    }
  }
  handleValidationUpgrad = () => {
    let formIsValid = true;
    if (!this.state.sub_plan) {
      formIsValid = false;
      errors['plan_error'] = 'Please select plan.';
    }
    if(!this.state.formValidation){
      formIsValid = false;
    }
    if(!this.state.fillStatus){
      this.setState({
        cardError:'Please enter card detail.'
      });
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  stripeElementChange = (element, name) => {
      this.setState({
        fillStatus: true,
        cardError:'',
        formValidation: true
      });
      if(element.error){
        if(element.error.code){
          this.setState({
            formValidation: false,
            cardError: element.error.message
          });
        }
      }else if(!element.complete){
        this.setState({
          fillStatus: false
        });
      }
    }
  async onToken() {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    if(result.error){
      this.setState({
        btnText:'Submit',
        btnDisable:false
      });
    }else{
      let formData = {
        "token":result.token.id,
        "planId": this.state.sub_plan,
        "userId": this.props.match.params.id,
        "emailId": this.state.userinfo.email
      };
      Axios.post(base_api_url + 'upgrade-featured.php', formData, { axiosConfig }).then((res) => {
        if(res.data.res_status == '200'){
          const { elements, stripe } = this.props;
        const cardElement = elements.getElement(CardElement);
        if(cardElement){
          cardElement.clear();
        }
        this.setState({
          sub_plan:''
        });
        toast.success(res.data.res_message);
        this.getData();
        this.getUserPlanDetail();
        }else{
          toast.error(res.data.res_message);
        }
        this.setState({
          btnText:'Submit',
          btnDisable:false
      });
      });
      
    }
  }
  cancelAllPlan = () => {
    if (window.confirm('Are you sure you want to cancel all plans?')) {
      const obj = {
        id: this.props.match.params.id,
        type: 'user',
        email:  this.state.userinfo.email,
        uname:  this.state.userinfo.first_name + ' ' + this.state.userinfo.last_name
      }  
     // console.log(obj);     
      Axios.post(base_api_url+'cancel-plan.php',obj,{axiosConfig})
      .then(
        (res) => {        
          if(res.data.res_status==200){
            toast.success(res.data.res_message);
            this.getData();
            this.getUserPlanDetail();
          }else{
            toast.error(res.data.res_message);
          }
        }
      );
    }
  }
  refundAmount = () => {
    if (window.confirm('Are you sure you want to refund amount?')) {
      //alert('refund');
      this.setState({
        refund_class: "fa fa-refresh fa-spin",
        disabled:"disabled"
      });
      const obj = {
        userid: this.props.match.params.id
      }
      Axios.post(base_api_url+'refund.php',obj,{axiosConfig}).then(
        (res) => {
          if(res.data.res_status==200){
            toast.success(res.data.res_message);
          }else{
            toast.error(res.data.res_message);
          }
          this.setState({
            refund_class: "fa fa-refresh",
            disabled:""
          })
        }
      );
    }
  }
  closeAccount = () => {
    if (window.confirm('Are you sure you want to close account. Once account is closed no amount will refund?')) {
      this.setState({
        disabled1:"disabled"
      });
      const obj = {
        userid: this.props.match.params.id
      };
      Axios.post(base_api_url+'closeaccount.php',obj,{axiosConfig}).then(
        (res) => {
          if(res.data.res_status==200){
            toast.success(res.data.res_message);
          }else{
            toast.error(res.data.res_message);
          }
          this.setState({
            disabled1:""
          })
        }
      );
    }
  }
  render() {
    const { userinfo } = this.state;

    if (this.state.loading == true) {
     // return <Spinner />;
    }
    return (
      <>
        <div class="dashboard-wrapper">
          <AdminHeader />
          <div class="maincontent-area">
            <AdminTopHeader />
            <div class="main-dashsection">
              <section class="contentpard">
                <div className="row">
                  <div className="col-md-12">
                    <div className="box box-success distributions-profileform artprofile">
                      <div className="box-header with-border addprofilenew">
                        <h3 className="box-title">User Profile</h3>
                        <div className="box-tools">
                        {userinfo.role === '2' ?
                          <Link to="#" className="btn btn-primary aadersongs" onClick={this.upgradeFeaturedProfile.bind(this)}>
                            <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Become a Featured Artist
                          </Link>:""}
                          {userinfo.is_free == '0'?
                          <>
                          <Link to="#" className="btn btn-primary aadersongs" onClick={this.upgradeFreeAccount.bind(this)}>
                            <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Free Account
                          </Link>
                          {(userinfo.subscription_id != '0')?
                            <>
                            <button type="button" className="btn btn-success aadersongs" onClick={this.refundAmount.bind(this)} disabled={this.state.disabled}>
                              <i className={this.state.refund_class} aria-hidden="true"></i>&nbsp;Refund Amount
                            </button>
                            <button type="button" className="btn btn-danger aadersongs" onClick={this.closeAccount.bind(this)} disabled={this.state.disabled1}>
                              <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Close Account
                            </button>
                            </>:""
                          }                          
                          </>
                          :""}
                          <Link to={'/admin/users'} className="btn btn-primary"><i className="fa fa-angle-double-left"></i>&nbsp;Back</Link>
                        </div>
                      </div>
                      <div className="box-body no-padding">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="sectionhead">
                              <div className="headsec">
                                <h3>Personal Information</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="firstname">Name</label>
                              <div className="decentvalue">
                                {userinfo.first_name + ' ' + userinfo.last_name}
                                {userinfo.role === '2' ? ' (Artist)' : ' (Listener)'}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="firstname">Email</label>
                              <div className="decentvalue">{userinfo.email}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="firstname">Phone</label>
                              <div className="decentvalue">
								{userinfo.phone
									? `(${userinfo.phone.substring(0, 3)}) ${userinfo.phone.substring(3, 6)}-${userinfo.phone.substring(
										6,
										userinfo.phone.length
									  )}`
									: ''}
                              </div>
                            </div>
                          </div>
                          {userinfo.role === '2' ? 
                          <>
                          <div className="col-md-4">
                            <div className="form-group">
                              {(userinfo.ssn=="null" || userinfo.ssn==null)?"":<>
                              <label for="lastname">SSN</label>
                              <div className="decentvalue">{userinfo.ssn}</div>
                              </>}
                            </div>
                          </div>
                          </>:""
                        }
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="firstname">DOB</label>
                              <div className="decentvalue">{userinfo.dob}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="lastname">Address</label>
                              <div className="decentvalue">{userinfo.address + ' ' + userinfo.zipcode}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="customFile">Registered Date</label>
                              <div className="decentvalue">{userinfo.created}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="customFile">Last Visited Date</label>
                              <div className="decentvalue">{userinfo.last_login}</div>
                            </div>
                          </div>
                          {userinfo.bank_name && userinfo.account_number ? (
                            <>
                              <div className="col-md-4">
                                <div className="form-group">
                                  {(userinfo.bank_name == "null")?"":<>
                                  <label for="customFile">Bank Account Name</label>
                                  <div className="decentvalue">{userinfo.bank_name}</div>
                                  </>
                                  }
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                {(userinfo.account_number=="null")?"":<>
                                  <label for="customFile">Bank Account Number</label>
                                  <div className="decentvalue">{userinfo.account_number}</div>
                                  </>
                                }
                                </div>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 profilepic">
                            <img
                              onError={this.addDefaultSrc}
                              src={userinfo.path}
                              width="100"
                              height="100"
                              className="img-responsive img-circle"
                              onClick={() => this.onclickseturl(userinfo.path)}
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div id="myModal" className="modal fade imgmodal" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <button type="button" className="btn btn-default closeicon" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body">
                  <img className="showimage img-responsive" src={this.state.urlvalinitial} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="upGradModal" className="modal fade imgmodal" role="dialog" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" style={{'padding':'5px 20px'}}>
                <h5 class="modal-title">Become a Featured Artist</h5>
                {(this.state.featuredData.length) ?
                <Link to="#" className="btn btn-primary aadersongs cancel-plan" onClick = {this.cancelAllPlan.bind(this)}><i className="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel all plan</Link>:""}
                <button type="button" className="btn btn-default closeicon" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
              </div>
              <form className="artist-form" onSubmit={this.onSubmit.bind(this)}>
                <div className="container">
                  <div class="modal-body">
                  {/*(userinfo.is_featured == '1')?*/}
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 class="modal-title" style={{'fontSize':'17px'}}>Purchased Plan</h5>
                        <div className="table-responsive no-padding">
                        <table className="table table-striped table-bordered table-sm row-border hover tbl-padding-tr">
                          <thead>
                          <tr>
                            <th>Plan</th>
                            <th>Start Date & Time</th>
                            <th>End Date & Time</th> 
                            <th>Status</th>                      
                          </tr>
                          </thead>
                          <tbody>
                          {(this.state.featuredData.length) ? this.state.featuredData.map((result) => {
                            return (<tr>
                              <td>{result.paln}</td>
                              <td>{result.start_from}</td>
                              <td>{result.end_to}</td>
                              <td>{result.status}</td>
                            </tr>)
                          }):<tr><td colspan="4" style={{'textAlign':'center'}}>No plan available!</td></tr>}
                          </tbody>                 
                        </table>
                        </div>
                      </div>
                    </div><hr style={{'width':'50%'}}/></>{/*:""*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Plan<span className="requiredastrik">*</span></label>
                          <select className="form-control" value={this.state.sub_plan} onChange={this.onChangePlan.bind(this)}>
                            <option value="">--Select Plan--</option>
                            {this.state.planlist.map((result) => {
                            return (<option value={result.id}>{result.plan} [ ${result.amount} ]</option>)
                            })}
                          </select>
                          <font className="errorMsg">{this.state.errors['plan_error']}</font>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Credit Card<span className="requiredastrik">*</span></label>
                          <div className="creditcardsection">
                            <CardElement options={{ hidePostalCode: true }}  onChange={(element) => this.stripeElementChange(element, 'card_number')} />
                          </div>
                          <font className="errorMsg">{this.state.cardError}</font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                <font style={{'position':'absolute','left':'15px','fontSize':'11px'}}>Note:- {this.state.stripe_text}</font>
                  <button type="submit" class="btn btn-primary" disabled={this.state.btnDisable}>{this.state.btnText}</button>
                </div>
              </form>             
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default function InjectedEventBooking({ match }) {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{({ stripe, elements }) => <UserDetails match={match} stripe={stripe} elements={elements} />}</ElementsConsumer>
    </Elements>
  );
}