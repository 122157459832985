import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../config/constants.js';
import ls from 'local-storage';
import { Spinner } from 'react-bootstrap';

const base_api_url = API_URL;
const axiosConfig = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
};
const errors = {};
export default class AddPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: '',
      title: '',
      description: '',
      successclass: 'hideclass',
      msg: '',
      errors: [],
      is_public: 'no'
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Add Playlist';
    const token = ls.get('token');
    this.setState({
      token: token,
    });
  }
  onChangeTitle(e) {
    if (this.state.errors['title_error']) { delete this.state.errors['title_error']; }
    this.setState({
      title: e.target.value
    });
  }
  playListIsPublic(e) {
    this.setState({
      is_public: e.target.value
    });
  }
  handleValidation = () => {
    let formIsValid = true;
    if (!this.state.title) {
      formIsValid = false;
      errors["title_error"] = "Please enter title.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  async onSubmit() {
    if (this.handleValidation()) {
      const obj = {
        title: this.state.title,
        description: this.state.description,
        is_public: this.state.is_public,
        device_id: '',
        userid: ls.get('userid'),
      };
      if (this.state.title !== '') {
        const response = await Axios.post(base_api_url + 'createplaylist.php', obj, axiosConfig);

        if (response.data.res_status === '200') {
          this.setState({ successclass: 'successmsg', msg: 'Playlist created Successfully' }, () => {
            setTimeout(() => {
              this.props.history.push(this.props.location.state.prevPath);
            }, 3000);
          });
        } else {
          this.setState({ successclass: 'errormsg', msg: 'Playlist Not Created, Please try again.' }, () => {
            setTimeout(() => {
              this.setState({ successclass: 'hideclass' });
            }, 3000);
          });
        }
      } else {
        this.setState({ successclass: 'errormsg', msg: 'Enter data in all Fields.' }, () => {
          setTimeout(() => {
            this.setState({ successclass: 'hideclass' });
          }, 3000);
        });
      }
    }
  }
  render() {
    let role = ls.get('role');
    let backurl = (role === '2') ? "/playlist-artist/" + this.state.token : "/playlist-listener/" + this.state.token;
    return (
      <React.Fragment>
        <div className={this.state.successclass}>{this.state.msg}</div>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-success distributions-profileform">
              <div className="box-header with-border addprofilenew">
                <h3 className="box-title">Add Playlist</h3>
                <div className="box-tools">
                  <Link className="btn btn-primary" to={backurl}>
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Back</Link>
                </div>
              </div>
              <div className="box-body no-padding havetabs">
                <div className="tab-content">
                  <div className={this.state.tabdetailclass} id="tab_1">
                    <form name="event-details" onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectionhead">
                            <div className="headsec">
                              <h3>Basic Information</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Title<span className="requiredastrik">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              name="eventtitle"
                              id="eventtitle"
                              value={this.state.title}
                              onChange={this.onChangeTitle.bind(this)}
                            />
                            <font className="errorMsg">{this.state.errors["title_error"]}</font>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Description</label>
                            <input
                              type="text"
                              value={this.state.description}
                              placeholder="Description"
                              className="form-control"
                              onChange={(e) => this.setState({ description: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Is Public?</label>
                            <select className="custom-select" name="genre" id="genre" onChange={this.playListIsPublic.bind(this)}>
                              <option value="no">No</option>
                              <option value="yes">Yes</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-md-12 notmainbtn">
                        <button onClick={() => this.onSubmit()} className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
