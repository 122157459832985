import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../../../config/constants.js';
import { Redirect, NavLink, Link, withRouter } from 'react-router-dom';
import AdminHeader from './../header.component';
import AdminTopHeader from './../header-top.component';
import Axios from 'axios';
import DOMPurify from "dompurify";
import Loader from "react-loader-spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import $ from 'jquery'; 
import Spinner from '../../../includes/Spinner.component';
const axiosConfig = {
		'Content-Type': 'multipart/form-data',
		"Access-Control-Allow-Origin": "*",
};
export default class adminPayment extends Component {
	constructor(props) {
    super(props);

    this.state = {
      userData: '',
      loading: true,
      loader: null,
      type: ''
    };
  }

  componentDidMount() {
    document.title = 'CONQER Music - Admin Revenue';
    this.getallreportlist();
  }
  changeDataFeatured = (e) => {
		this.setState({ type: e.target.value }, () => {
			this.getallreportlist();
		});
	};
  getallreportlist = () => {
    this.setState({loading:true});
    let obj = {type: this.state.type};
	setTimeout(() => {
        $('#myevents').DataTable({
			"ajax": { type: 'POST', url: API_URL + 'adminrevenue.php', data: obj },
			"destroy": true,
			"processing": false,
			"pageLength": 10,
			"language": {
				"search": "Search"
			},
			"stateSave": true,
			"dom": 'Bfrtip',
			"buttons": [
				{
					"extend": 'csv',
					"exportOptions": {
						"columns": [ 0,1,2,3 ]
					}
				},
				{
					"extend": 'print',
					"exportOptions": {
						"columns": [ 0,1,2,3 ]
					}
				}
			],
			"columnDefs": [
				{ "type": "num-fmt", "targets": 2, "className": "text-center", "appliesTo": "print" },
				{ "type": "num-fmt", "targets": 3, "className": "text-center", "appliesTo": "print" }
			],
			"bSort": false
		});
      }, 500);
	  this.setState({loading:false})
  };
    
    render() {
		const {userData} = this.state;
    	return(
    		<React.Fragment>
    		<div className="dashboard-wrapper">
    		<AdminHeader />
    		<div className="maincontent-area">
    		<AdminTopHeader />
    		<div className="main-dashsection">
    			<section className="contentpard">
				{(this.state.loader === true)?<Spinner />:
					<div className="row">
						<div className="col-md-12">
							<div className="box box-success distributions-profileform">
								<div className="box-header with-border addprofilenew">
								  	<h3 className="box-title">Admin's Revenue List</h3>
								  	<div className="box-tools d-flex justify-content-between">
										<select
											className="filteroptionselect"
											as="select"
											value={this.state.featuredStatus}
											onChange={(e) => this.changeDataFeatured(e)}
											style={{ width: '15vw' }}
										  >
										  	<option value="">All</option>
										  	<option value="isrc">ISRC Revenue</option>
											<option value="registration">Registration Revenue</option>
											<option value="song">Song Revenue</option>
											<option value="ticket">Ticket Revenue</option>
										</select>
									</div>
								</div>
								<div className="box-body no-padding">
								  <div className="table-responsive">
									<table id="myevents" className="table no-margin table-striped table-hover">
									<thead>
									  <tr>
									  	<th>Description</th>
										<th>Date</th>										
										<th className="text-center">Wallet Credit ($)</th>
										<th className="text-center">Wallet Debit ($)</th>
									  </tr>
									</thead>
									
								</table>
							  </div>              
							</div>
							</div>
						</div>        
					</div>
				}
    			</section>
    		</div>
    		</div>
    		</div>
    		</React.Fragment>
    	)
    }
}